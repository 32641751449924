import axios from "axios"
import { cloneDeep } from "lodash"
import {
    entityTypesEnum,
    abilitiesEnum,
    characteristicsEnum,
    scoreTypesEnum,
    resultTypesEnum,
    wordsEnum,
    wordsModifiers,
    gesturesEnum,
    gesturesModifiers,
    magicTypesEnum,
    attackResultsEnum,
    woundStatesEnum,
    woundTypes,
    woundsModifiers,
    modifyFatigueActionsEnum,
    fatigueTypesEnum,
    fatigueDescriptions,
    fatigueModifiers,
    virtuesEnum,
    initiativeCheckTypesEnum,
    scuffleEffects,
} from "./Enums"

export const handleLoading = ({ requestStrings, handleData }) => {
    console.log(requestStrings)
    const promises = requestStrings.map((el) => axios.get(el))
    Promise.all(promises).then((responses) => {
        console.log("Data returned from:", requestStrings)
        const newData = responses.map((el) => el.data)
        console.log(newData)
        handleData(newData)
    })
}

export const handleUploading = ({ requestString, body, handleData }) => {
    axios.post(requestString, body).then((response) => {
        const newData = response.data
        handleData(newData)
    })
}

export const handleDeleting = ({ requestString, handleData }) => {
    console.log("handleDelete - requestString:", requestString)
    axios.delete(requestString).then((response) => {
        const newData = response.data
        handleData(newData)
    })
}

export const displayName = (character) => {
    return character.type === entityTypesEnum.NPC && character.id !== undefined
        ? `${character.name} (id:${character.id})`
        : character.name
}

export const formatModifier = (number) => {
    return number > 0 ? `+${number}` : `${number}`
}

export const capitalCase = (string) => {
    return string.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
        return str.toUpperCase()
    })
}

export const parseNum = (string) => {
    const num = parseInt(string)
    if (isNaN(num)) {
        return 0
    } else {
        return num
    }
}

export const getScore = ({ character, scoreType, scoreName }) => {
    if (character && scoreType && scoreName) {
        return character[scoreType][scoreName]
            ? character[scoreType][scoreName].score
            : 0
    } else {
        return 0
    }
}

export const simpleDie = (numBotchDice = 0) => {
    const roll = Math.floor(Math.random() * 10 + 1)
    return { roll, botch: 0 }
}

export const simpleDieCheck = () => {
    return { type: resultTypesEnum.DICE, values: simpleDie() }
}

export const multipleDie = (factor) => {
    let newFactor = factor ? factor : 2
    let newRoll = null
    const { roll } = simpleDie()
    if (roll === 1) {
        newFactor = 2 * newFactor
        const obj = multipleDie(newFactor)
        newRoll = obj.roll
        newFactor = obj.factor
    } else {
        newRoll = roll * newFactor
    }
    return { roll: newRoll, factor: newFactor }
}

export const botchRoll = (numBotchDice) => {
    let rolls = []
    for (let ind = 0; ind < numBotchDice; ind++) {
        rolls.push(Math.floor(Math.random() * 10))
    }
    return rolls.filter((roll) => roll === 0).length
}

export const stressDie = (numBotchDice = 1) => {
    let roll = null
    let botch = 0
    const newRoll = Math.floor(Math.random() * 10)
    if (newRoll >= 2 && newRoll <= 9) {
        roll = newRoll
    } else if (newRoll === 1) {
        roll = multipleDie().roll
    } else if (newRoll === 0) {
        roll = 0
        botch = botchRoll(numBotchDice)
    }
    return { roll, botch }
}

export const stressDieCheck = (numBotchDice) => {
    return { type: resultTypesEnum.DICE, values: stressDie(numBotchDice) }
}

export const initiativeCheck = (props) => {
    const { type, character, weapon } = props
    const quicknessScore = getScore({
        character,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.QIK,
    })
    const encumbranceModifier = getEncumbrance({ character }).modifier
    const { roll, botch } = stressDie()
    let weaponInitiativeModifier = 0
    if (weapon && type === initiativeCheckTypesEnum.WEAPON) {
        weaponInitiativeModifier = weapon.initiative
    }
    let fastCasterModifier = 0
    if (
        type === initiativeCheckTypesEnum.SPELL &&
        character.virtues &&
        virtuesEnum.FAST_CASTER in character.virtues
    ) {
        fastCasterModifier = 3
    }
    const total =
        quicknessScore +
        weaponInitiativeModifier +
        fastCasterModifier +
        encumbranceModifier +
        roll
    return {
        type: resultTypesEnum.INITIATIVE,
        values: {
            ...props,
            total,
            roll,
            botch,
            quicknessScore,
            weaponInitiativeModifier,
            encumbranceModifier,
            fastCasterModifier,
        },
    }
}

export const getEncumbrance = ({ character }) => {
    const { weapons, armor, equipment } = character
    const strengthScore = getScore({
        character,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.STR,
    })

    const weaponLoad = !weapons ? 0 : weapons.reduce((a, c) => a + c.load, 0)
    const armorLoad = !armor ? 0 : armor.load
    const equipmentLoad = !equipment
        ? 0
        : equipment.reduce((a, c) => a + c.load, 0)
    const load = weaponLoad + armorLoad + equipmentLoad
    const getBurden = (load) => {
        let burden = 0
        let loadLevel = 0
        if (load === 0) {
            return 0
        } else {
            while (load >= loadLevel) {
                loadLevel += burden + 1
                burden += 1
            }
            if (burden > 1) {
                burden -= 1
            }
            return burden
        }
    }
    const burden = getBurden(load)
    let modifier = 0
    if (strengthScore <= 0) {
        modifier = -burden
    } else {
        modifier = strengthScore - burden
    }
    return {
        modifier,
        weaponLoad,
        armorLoad,
        equipmentLoad,
    }
}

export const actionCheck = (props) => {
    let { easeFactor, totalModifier, stress, numBotchDice } = props
    totalModifier = totalModifier ? totalModifier : 0
    numBotchDice =
        numBotchDice !== null && numBotchDice !== undefined ? numBotchDice : 1
    const dieResult = stress ? stressDie(numBotchDice) : simpleDie()
    const roll = dieResult.roll
    const total = dieResult.roll + totalModifier
    const checkPassed = total >= easeFactor && !dieResult.botch ? true : false
    return { ...props, total, roll, botch: dieResult.botch, checkPassed }
}

export const abilityCheck = (props) => {
    let {
        character,
        easeFactor,
        characteristicScore,
        abilityScore,
        specialized,
        fatigue,
        wounds,
        modifier,
        stress,
        numBotchDice,
        useEncumbranceModifier,
    } = props
    const { fatigueModifier, woundsModifier } = getFatigueWoundsObj({
        character,
        fatigue,
        wounds,
    })
    modifier = modifier ? modifier : 0
    const specializedModifier = specialized ? 1 : 0
    const encumbranceModifier = useEncumbranceModifier
        ? getEncumbrance({ character }).modifier
        : 0
    const totalModifier =
        characteristicScore +
        abilityScore +
        fatigueModifier +
        woundsModifier +
        encumbranceModifier +
        modifier +
        specializedModifier
    const { total, roll, botch, checkPassed } = actionCheck({
        easeFactor,
        totalModifier,
        stress,
        numBotchDice,
    })
    const values = {
        ...props,
        total,
        roll,
        botch,
        checkPassed,
        specializedModifier,
        fatigueModifier,
        woundsModifier,
        encumbranceModifier,
        totalModifier,
    }
    return { type: resultTypesEnum.ABILITY, values }
}

export const spellCheck = (props) => {
    let {
        character,
        spell,
        magicType,
        fastCasting,
        ceremonialCasting,
        pawns,
        words,
        gestures,
        auraModifier,
        penetrationModifier,
        mustConcentrate,
        concentrationEaseFactor,
        modifier,
        stress,
        numBotchDice,
    } = props
    const spellLevel = spell.level
    //if requisites, use lowest of techniques and forms
    const techniqueScore = Math.min(
        ...[spell.technique, ...spell.requisites.techniques].map((technique) =>
            getScore({
                character,
                scoreType: scoreTypesEnum.ARTS,
                scoreName: technique,
            })
        )
    )
    const formScore = Math.min(
        ...[spell.form, ...spell.requisites.forms].map((form) =>
            getScore({
                character,
                scoreType: scoreTypesEnum.ARTS,
                scoreName: form,
            })
        )
    )
    const staminaScore = getScore({
        character,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.STA,
    })
    const concentrationScore = getScore({
        character,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.CONCENTRATION,
    })
    auraModifier = auraModifier ? auraModifier : 0
    const artesLiberalesScore = getScore({
        character,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.ARTES_LIBERALES,
    })
    const philosophiaeScore = getScore({
        character,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.PHILOSOPHIAE,
    })
    const penetrationScore = getScore({
        character,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.PENETRATION,
    })
    penetrationModifier = penetrationModifier ? penetrationModifier : 0
    mustConcentrate = mustConcentrate ? true : false

    let mastery = 0
    const matches = Object.keys(character.abilities).filter((ability) =>
        ability.includes(spell.name)
    )
    if (matches.length === 1) {
        mastery = character.abilities[matches[0]].score
    }
    ceremonialCasting = ceremonialCasting ? ceremonialCasting : 0
    pawns = pawns ? pawns : { number: 0, art: null }
    words = words && !fastCasting ? words : wordsEnum.FIRM
    gestures = gestures && !fastCasting ? gestures : gesturesEnum.BOLD

    let spellModifier = 0
    let botchDiceModifier = 0
    let notes = []
    let numPawnsUsed = 0

    if (pawns.number > 0) {
        const artScore = getScore({
            character,
            scoreType: scoreTypesEnum.ARTS,
            scoreName: pawns.art,
        })
        if (artScore < pawns.number) {
            numPawnsUsed = artScore
            notes.push("Number of raw vis pawns used limited by art score")
        } else {
            numPawnsUsed = pawns.number
        }
        spellModifier += 2 * numPawnsUsed
    }

    if (magicType !== magicTypesEnum.RITUAL) {
        spellModifier += wordsModifiers.filter((el) => el.type === words)[0]
            .modifier
        spellModifier += gesturesModifiers.filter(
            (el) => el.type === gestures
        )[0].modifier
    } else if (magicType === magicTypesEnum.RITUAL) {
        notes.push("Words and/or gestures have no effect with ritual magic")
    }

    if (fastCasting) {
        spellModifier -= 10
        botchDiceModifier += 2
    }

    if (ceremonialCasting) {
        const artesLiberalesScore = getScore({
            character,
            scoreType: scoreTypesEnum.ABILITIES,
            scoreName: abilitiesEnum.ARTES_LIBERALES,
        })
        const philosophiaeScore = getScore({
            character,
            scoreType: scoreTypesEnum.ABILITIES,
            scoreName: abilitiesEnum.PHILOSOPHIAE,
        })
        spellModifier += artesLiberalesScore + philosophiaeScore
    }

    if (mastery) {
        spellModifier += mastery
        botchDiceModifier -= mastery
    }
    const { fatigueModifier, woundsModifier } = getFatigueWoundsObj({
        character,
    })
    const encumbranceModifier = getEncumbrance({ character }).modifier
    modifier = modifier ? modifier : 0
    const totalModifier = modifier + spellModifier
    numBotchDice =
        numBotchDice !== null && numBotchDice !== undefined ? numBotchDice : 1
    numBotchDice += botchDiceModifier

    let concentrationDieResult = stressDie(numBotchDice)
    const concentrationDieRoll = concentrationDieResult.roll
    let concentrationTotal = null
    let concentrationValue = null
    if (mustConcentrate) {
        concentrationTotal =
            staminaScore + concentrationScore + concentrationDieRoll
        concentrationValue = concentrationTotal - concentrationEaseFactor
    }
    if (concentrationValue != null && concentrationValue < 0) {
        numBotchDice += 1
    }
    let castingScore =
        techniqueScore +
        formScore +
        staminaScore +
        fatigueModifier +
        woundsModifier +
        encumbranceModifier +
        auraModifier +
        totalModifier
    const die =
        stress || magicType === magicTypesEnum.SPONTANEOUS_FATIGUING
            ? stressDie
            : simpleDie
    const { roll, botch } = die(numBotchDice)
    let castingTotal = null //castingScore + die
    let castingValue = null //castingTotal - spellLevel
    let spellCast = null
    let fatigueCaused = null
    if (magicType === magicTypesEnum.FORMULAIC) {
        castingTotal = castingScore + roll
        castingValue = castingTotal - spellLevel
        if (castingValue >= 0) {
            spellCast = true
            fatigueCaused = 0
        } else if (castingValue <= -1 && castingValue >= -10) {
            spellCast = true
            fatigueCaused = 1
        } else if (castingValue <= -11) {
            spellCast = false
            fatigueCaused = 1
        }
    } else if (magicType === magicTypesEnum.RITUAL) {
        castingTotal =
            castingScore + artesLiberalesScore + philosophiaeScore + roll
        castingValue = castingTotal - spellLevel
        if (castingValue >= 0) {
            spellCast = true
            fatigueCaused = 1
        } else if (castingValue <= -1 && castingValue >= -5) {
            spellCast = true
            fatigueCaused = 2
        } else if (castingValue <= -6 && castingValue >= -10) {
            spellCast = true
            fatigueCaused = 3
        } else if (castingValue <= -11 && castingValue >= -15) {
            spellCast = false
            fatigueCaused = 4
        } else if (castingValue <= -16) {
            spellCast = false
            fatigueCaused = 5
        }
    } else if (magicType === magicTypesEnum.SPONTANEOUS_FATIGUING) {
        castingTotal = Math.floor((castingScore + roll) / 2)
        castingValue = castingTotal - spellLevel
        spellCast = castingValue >= 0 ? true : false
        fatigueCaused = 1
    } else if (magicType === magicTypesEnum.SPONTANEOUS_NONFATIGUING) {
        castingTotal = Math.floor(castingScore / 5)
        castingValue = castingTotal - spellLevel
        spellCast = castingValue >= 0 ? true : false
        fatigueCaused = 0
    }
    if ((concentrationValue != null && concentrationValue < 0) || botch > 0) {
        spellCast = false
    }
    const penetrationTotal =
        castingTotal + penetrationScore + penetrationModifier - spellLevel

    return {
        type: resultTypesEnum.SPELL,
        values: {
            ...props,
            roll,
            botch,
            castingTotal,
            castingValue,
            spellCast,
            fatigueCaused,
            concentrationDieRoll,
            concentrationTotal,
            concentrationValue,
            penetrationTotal,
            numBotchDice,
            numPawnsUsed,
            notes,
        },
    }
}

export const aimingCheck = ({ character, stress, numBotchDice }) => {
    const { fatigueModifier, woundsModifier } = getFatigueWoundsObj({
        character,
    })
    numBotchDice =
        numBotchDice !== null && numBotchDice !== undefined ? numBotchDice : 1
    const finesseScore = getScore({
        character,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.FINESSE,
    })
    const perceptionScore = getScore({
        character,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.PER,
    })
    const die = stress ? stressDie : simpleDie
    const dieResult = die(numBotchDice)
    const total =
        finesseScore +
        perceptionScore +
        fatigueModifier +
        woundsModifier +
        dieResult.roll
    return {
        type: resultTypesEnum.AIMING,
        values: {
            total,
            finesseScore,
            perceptionScore,
            fatigueModifier,
            woundsModifier,
            roll: dieResult.roll,
            botch: dieResult.botch,
            stress,
            numBotchDice,
        },
    }
}

export const didFastCastingSucceed = ({
    character,
    easeFactor,
    fastCastIndex,
}) => {
    const { fatigueModifier, woundsModifier } = getFatigueWoundsObj({
        character,
    })
    const modifier = -6 * fastCastIndex //fastCastIndex - index in sequence of spells cast during 1 combat round, 0 start
    const finesseScore = getScore({
        character,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.FINESSE,
    })
    const quicknessScore = getScore({
        character,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.QIK,
    })
    const fastCastingSpeed =
        quicknessScore +
        finesseScore +
        fatigueModifier +
        woundsModifier +
        modifier +
        stressDie().roll
    return fastCastingSpeed > easeFactor
}

// TODO - p. 83 - What is the roll for the actual defense, rules only listed for recognizing form
export const fastCastDefenseFormIdentified = ({ character, spell }) => {
    const { fatigueModifier, woundsModifier } = getFatigueWoundsObj({
        character,
    })
    const { form, magnitude } = spell
    if (
        getScore({
            character,
            scoreType: scoreTypesEnum.ARTS,
            scoreName: form,
        }) > 0
    ) {
        const perceptionScore = getScore({
            character,
            scoreType: scoreTypesEnum.CHARACTERISTICS,
            scoreName: characteristicsEnum.PER,
        })
        const awarenessScore = getScore({
            character,
            scoreType: scoreTypesEnum.ABILITIES,
            scoreName: abilitiesEnum.AWARENESS,
        })
        const result =
            perceptionScore +
            awarenessScore +
            fatigueModifier +
            woundsModifier +
            stressDie().roll -
            (15 - magnitude)
        return result >= 0
    } else {
        console.log("Magus cannot defend without knowing form")
        return false
    }
}

export const combatCheck = (props) => {
    let {
        attackingCharacter,
        attackCombatAbility,
        attackWeapons,
        attackModifier,
        rangedAttack,
        range,
        attackExertion,
        attackMounted,
        defendingCharacter,
        defenseCombatAbility,
        defenseWeapons,
        defenseModifier,
        defenseExertion,
        defenseMounted,
        armor,
    } = props
    const attackDexterityScore = getScore({
        character: attackingCharacter,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.DEX,
    })
    const attackStrengthScore = getScore({
        character: attackingCharacter,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.STR,
    })
    const defenseQuicknessScore = getScore({
        character: defendingCharacter,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.QIK,
    })
    const defenseStaminaScore = getScore({
        character: defendingCharacter,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.STA,
    })
    const attackCombatAbilityScore = getScore({
        character: attackingCharacter,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: attackCombatAbility,
    })
    const defenseCombatAbilityScore = !defenseCombatAbility
        ? 0
        : getScore({
              character: defendingCharacter,
              scoreType: scoreTypesEnum.ABILITIES,
              scoreName: defenseCombatAbility,
          })
    const attackExertionModifier = attackExertion ? attackCombatAbilityScore : 0
    const attackFatigueCaused = attackExertion ? 1 : 0
    attackModifier = attackModifier ? attackModifier : 0
    const attackRideAbilityScore = getScore({
        character: attackingCharacter,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.RIDE,
    })
    const attackMountedModifier = attackMounted
        ? Math.min(attackRideAbilityScore, 3)
        : 0

    const defenseExertionModifier = defenseExertion
        ? defenseCombatAbilityScore
        : 0
    const defenseFatigueCaused = defenseExertion ? 1 : 0
    defenseModifier = defenseModifier ? defenseModifier : 0
    const defenseRideAbilityScore = getScore({
        character: defendingCharacter,
        scoreType: scoreTypesEnum.ABILITIES,
        scoreName: abilitiesEnum.RIDE,
    })
    const defenseMountedModifier = defenseMounted
        ? Math.min(defenseRideAbilityScore, 3)
        : 0

    const {
        fatigueModifier: attackFatigueModifier,
        woundsModifier: attackWoundsModifier,
    } = getFatigueWoundsObj({
        character: attackingCharacter,
    })
    const {
        fatigueModifier: defenseFatigueModifier,
        woundsModifier: defenseWoundsModifier,
    } = getFatigueWoundsObj({
        character: defendingCharacter,
    })
    const weaponAttackModifier = attackWeapons
        .map((weapon) => weapon.attack)
        .reduce((a, c) => {
            return a + c
        }, 0)
    const weaponDamageModifier = attackWeapons
        .map((weapon) => weapon.damage)
        .reduce((a, c) => {
            return a + c
        }, 0)
    const weaponDefenseModifier =
        defenseWeapons.length === 0
            ? 0
            : defenseWeapons
                  .filter((weapon) => !rangedAttack || weapon.shield)
                  .map((weapon) => weapon.defense)
                  .reduce((a, c) => {
                      return a + c
                  }, 0)
    const armorSoakBonus = armor.protection

    const rangedAttackModifier = rangedAttack
        ? -3 * Math.floor(range / attackWeapons[0].range)
        : 0

    const attackDieResult = stressDie()
    const attackDieRoll = attackDieResult.roll
    const attackDieBotch = attackDieResult.botch
    let attackTotal = 0
    if (attackDieResult.botch >= 0) {
        attackTotal =
            attackDexterityScore +
            attackCombatAbilityScore +
            attackExertionModifier +
            attackMountedModifier +
            weaponAttackModifier +
            rangedAttackModifier +
            attackFatigueModifier +
            attackWoundsModifier +
            attackModifier +
            attackDieResult.roll
    }
    const defenseDieResult = stressDie()
    const defenseDieRoll = defenseDieResult.roll
    const defenseDieBotch = defenseDieResult.botch
    let defenseTotal = 0
    if (defenseDieResult.botch <= 0) {
        defenseTotal =
            defenseQuicknessScore +
            defenseCombatAbilityScore +
            defenseExertionModifier +
            attackMountedModifier +
            weaponDefenseModifier +
            defenseFatigueModifier +
            defenseWoundsModifier +
            defenseModifier +
            defenseDieResult.roll
    }
    const attackAdvantage = attackTotal - defenseTotal
    let damageTotal = null
    let soakTotal = null
    let damageTaken = 0
    let result = null
    let woundTaken = null
    let scuffleEffect = null
    if (attackAdvantage <= 0) {
        result = attackResultsEnum.MISS
    } else {
        result = attackResultsEnum.HIT
        damageTotal =
            attackStrengthScore + weaponDamageModifier + attackAdvantage
        soakTotal = defenseStaminaScore + armorSoakBonus
        damageTaken = Math.max(damageTotal - soakTotal, 0)
        woundTaken = woundResult({ character: defendingCharacter, damageTaken })
        if (woundTaken) {
            scuffleEffect = scuffleEffects[woundTaken]
        }
    }

    return {
        type: resultTypesEnum.COMBAT,
        values: {
            ...props,
            result,
            attackTotal,
            defenseTotal,
            damageTotal,
            soakTotal,
            damageTaken,
            woundTaken,
            scuffleEffect,
            attackDieRoll,
            attackDieBotch,
            attackDexterityScore,
            attackStrengthScore,
            attackFatigueModifier,
            attackWoundsModifier,
            attackExertion,
            attackExertionModifier,
            attackFatigueCaused,
            attackMounted,
            attackMountedModifier,
            defenseDieRoll,
            defenseDieBotch,
            defenseQuicknessScore,
            defenseStaminaScore,
            defenseFatigueModifier,
            defenseWoundsModifier,
            defenseExertion,
            defenseExertionModifier,
            defenseMounted,
            defenseMountedModifier,
            defenseFatigueCaused,
            attackCombatAbilityScore,
            defenseCombatAbilityScore,
            attackModifier,
            defenseModifier,
            weaponAttackModifier,
            weaponDamageModifier,
            weaponDefenseModifier,
            armorSoakBonus,
            rangedAttackModifier,
            attackDieResult,
            defenseDieResult,
            attackAdvantage,
        },
    }
}

export const woundResult = ({ character, damageTaken }) => {
    const rangeWidth = 5 + character.size
    if (damageTaken > 0) {
        const index = Math.min(Math.floor((damageTaken - 1) / rangeWidth), 4)
        return woundsModifiers[index].type
    } else {
        return null
    }
}

//fatigue is recorded as array of strings, either "Short" or "Long"
export const changeFatigue = ({ fatigue, action, term }) => {
    let newFatigue = cloneDeep(fatigue)
    if (action === modifyFatigueActionsEnum.ADD) {
        newFatigue.push(term)
        newFatigue.sort() //will move "long"s to beginning so "short"s are popped first (p.178)
    } else if (action === modifyFatigueActionsEnum.SUBTRACT) {
        newFatigue.pop()
    } else if (action === modifyFatigueActionsEnum.FULLREST) {
        newFatigue = []
    }
    return newFatigue
}

export const addWound = ({ wounds, newWoundType }) => {
    wounds.push(
        cloneDeep(woundsModifiers.filter((el) => el.type === newWoundType)[0])
    )
    sortWounds(wounds)
    return {
        type: resultTypesEnum.WOUNDS,
        values: { wounds, woundState: getWoundState(wounds) },
    }
}

export const recoveryCheck = (props) => {
    let { character, woundIndex, medic, medicAbility, magicalAid, modifier } =
        props
    let newWounds = cloneDeep(character.wounds)
    let checkWound = newWounds[woundIndex]
    const staminaScore = getScore({
        character,
        scoreType: scoreTypesEnum.CHARACTERISTICS,
        scoreName: characteristicsEnum.STA,
    })
    const medicAbilityScore = medic
        ? getScore({
              character: medic,
              scoreType: scoreTypesEnum.ABILITIES,
              scoreName: medicAbility,
          })
        : 0
    const rapidConvalescenceModifier =
        character.virtues &&
        virtuesEnum.RAPID_CONVALESCENCE in character.virtues
            ? 3
            : 0
    const woundRecoveryModifier = checkWound.recoveryModifier
    magicalAid = magicalAid ? magicalAid : 0
    modifier = modifier ? modifier : 0
    const { roll } = stressDie()
    const recoveryTotal =
        staminaScore +
        medicAbilityScore +
        magicalAid +
        rapidConvalescenceModifier +
        modifier +
        woundRecoveryModifier +
        roll
    let recoveryResult = ""
    if (
        [woundTypes.LIGHT, woundTypes.MEDIUM, woundTypes.HEAVY].includes(
            checkWound.type
        )
    ) {
        if (recoveryTotal >= checkWound.improvementEaseFactor) {
            recoveryResult = "Improved"
            if (checkWound.type === woundTypes.LIGHT) {
                checkWound = null
            } else if (checkWound.type === woundTypes.MEDIUM) {
                checkWound = {
                    ...woundsModifiers.filter(
                        (el) => el.type === woundTypes.LIGHT
                    )[0],
                }
            } else if (checkWound.type === woundTypes.HEAVY) {
                checkWound = {
                    ...woundsModifiers.filter(
                        (el) => el.type === woundTypes.MEDIUM
                    )[0],
                }
            }
        } else if (recoveryTotal >= checkWound.stableEaseFactor) {
            recoveryResult = "Stabilized"
            checkWound.recoveryModifier += 3
        } else if (recoveryTotal < checkWound.stableEaseFactor) {
            recoveryResult = "Worsened"
            if (checkWound.type === woundTypes.LIGHT) {
                checkWound = {
                    ...woundsModifiers.filter(
                        (el) => el.type === woundTypes.MEDIUM
                    )[0],
                }
            } else if (checkWound.type === woundTypes.MEDIUM) {
                checkWound = {
                    ...woundsModifiers.filter(
                        (el) => el.type === woundTypes.HEAVY
                    )[0],
                }
            } else if (checkWound.type === woundTypes.HEAVY) {
                checkWound = {
                    ...woundsModifiers.filter(
                        (el) => el.type === woundTypes.INCAPACITATING
                    )[0],
                }
            }
        }
        newWounds.splice(woundIndex, 1, checkWound)
    } else if (checkWound.type === woundTypes.INCAPACITATING) {
        const incapacitatingIndices = newWounds.reduce((acc, cur, index) => {
            if (cur.type === woundTypes.INCAPACITATING) {
                acc.push(index)
            }
            return acc
        }, [])
        if (recoveryTotal <= 0) {
            recoveryResult = "Death"
            checkWound = {
                ...woundsModifiers.filter(
                    (el) => el.type === woundTypes.DEAD
                )[0],
            }
        } else if (recoveryTotal > 0 && recoveryTotal < 9) {
            recoveryResult = "Worsened"
            const newRecoveryModifier = checkWound.recoveryModifier - 1
            checkWound = {
                ...woundsModifiers.filter(
                    (el) => el.type === woundTypes.INCAPACITATING
                )[0],
            }
            checkWound.recoveryModifier = newRecoveryModifier
        } else if (recoveryTotal >= 9) {
            recoveryResult = "Improved"
            checkWound = {
                ...woundsModifiers.filter(
                    (el) => el.type === woundTypes.HEAVY
                )[0],
            }
        }
        for (const incapacitatingIndex of incapacitatingIndices) {
            const newWound = { ...checkWound }
            newWounds.splice(incapacitatingIndex, 1, newWound)
        }
    }
    newWounds = newWounds.filter((el) => el !== null) //trim healed wounds
    sortWounds(newWounds)
    return {
        type: resultTypesEnum.WOUNDS,
        values: {
            ...props,
            roll,
            recoveryResult,
            checkWound,
            staminaScore,
            medicAbilityScore,
            woundRecoveryModifier,
            magicalAid,
            rapidConvalescenceModifier,
            modifier,
            recoveryTotal,
            newWounds,
            wounds: newWounds,
            woundState: getWoundState(newWounds),
        },
    }
}

export const getWoundState = (wounds) => {
    return wounds.some((el) => el.type === woundTypes.DEAD)
        ? woundStatesEnum.DEAD
        : wounds.some((el) => el.type === woundTypes.INCAPACITATING)
        ? woundStatesEnum.INCAPACITATED
        : woundStatesEnum.CONSCIOUS
}

export const sortWounds = (wounds) => {
    wounds.sort((a, b) => b.rank - a.rank)
}

export const getFatigueWoundsObj = ({ character }) => {
    const { fatigue, wounds } = character
    const index = Math.min(fatigue.length, 5)
    const fatigueDescription = fatigueDescriptions[index]
    let fatigueModifier = fatigueModifiers.filter(
        (el) => el.type === fatigueDescription
    )[0].modifier
    let woundsModifier = wounds.reduce((prev, cur) => prev + cur.modifier, 0)
    if (
        character.virtues &&
        virtuesEnum.ENDURING_CONSTITUTION in character.virtues
    ) {
        fatigueModifier = Math.min(fatigueModifier + 1, 0)
        woundsModifier = Math.min(woundsModifier + 1, 0)
    }
    let fatigueRecoveryTime = "None"
    if (fatigue.length > 0) {
        if (fatigue.slice(-1)[0] === fatigueTypesEnum.LONG) {
            fatigueRecoveryTime = "Night's Rest"
        } else {
            const fatigueRecoveryTimesMinutes = [0, 2, 10, 30, 60, 120] //minutes
            let fatigueRecoveryTimeMinutes =
                fatigueRecoveryTimesMinutes[Math.min(fatigue.length, 5)]
            if (fatigue.length > 5) {
                fatigueRecoveryTimeMinutes += 60 * (fatigue.length - 5)
            }
            fatigueRecoveryTime =
                fatigueRecoveryTimeMinutes < 60
                    ? `${fatigueRecoveryTimeMinutes} Minutes`
                    : `${fatigueRecoveryTimeMinutes / 60} Hours`
        }
    }

    return {
        fatigue,
        fatigueDescription,
        fatigueModifier,
        fatigueRecoveryTime,
        wounds,
        woundsModifier,
    }
}

//Notes:
//Not currently applying fatigue modifier to recovery rolls

//To add:
//p. 115 Form/technique requisites
//p.83 Fast-cast defense
//p.84 Arcane connection penetration bonus
//p.85 Magic resistance and parma magica
//p. 173 More combat effects? Group combat?

import { cloneDeep } from "lodash"
import { Button, Column, Title, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"
import { changeFatigue, getFatigueWoundsObj } from "./Utils"
import { actionTypesEnum, fatigueTypesEnum, modifyFatigueActionsEnum } from "./Enums"

export default (props) => {
    const { openResult, closeAction, character, handleUpdateCharacter } = props

    const handleModifyFatigue = ({ action, term }) => {
        let { fatigue } = character
        const newFatigue = changeFatigue({ fatigue, action, term })
        const newCharacter = { ...cloneDeep(character), fatigue: newFatigue }
        handleUpdateCharacter({ newCharacter })
        openResult({
            type: actionTypesEnum.FATIGUE, values: getFatigueWoundsObj({ character: newCharacter })
        })
    }

    return (
        <>
            <ActionTitleArea>
                <Title>Fatigue</Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    <Button onClick={() => { handleModifyFatigue({ action: modifyFatigueActionsEnum.ADD, term: fatigueTypesEnum.LONG }) }}>Add (Long Term)</Button>
                    <Button onClick={() => { handleModifyFatigue({ action: modifyFatigueActionsEnum.ADD, term: fatigueTypesEnum.SHORT }) }}>Add (Short Term)</Button>
                    <Button onClick={() => { handleModifyFatigue({ action: modifyFatigueActionsEnum.SUBTRACT }) }}>Subtract</Button>
                    <Button onClick={() => { handleModifyFatigue({ action: modifyFatigueActionsEnum.FULLREST }) }}>Full Rest</Button>
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Button onClick={closeAction}>Cancel</Button>
            </ActionButtonsArea>
        </>
    )
}

export const entityTypesEnum = {
    CAMPAIGN: "campaign",
    CHARACTER: "character",
    NPC: "npc",
}

export const characterClassEnum = {
    MAGUS: "Magus",
    COMPANION: "Companion",
    GROG: "Grog",
    CREATURE: "Creature",
}

export const entityRouteActionsEnum = {
    INSTANTIATE: "instantiate",
    UPDATE: "update",
}

export const scoreTypesEnum = {
    CHARACTERISTICS: "characteristics",
    ARTS: "arts",
    ABILITIES: "abilities",
}

export const characteristicsEnum = {
    INT: "Int",
    PER: "Per",
    STR: "Str",
    STA: "Sta",
    PRS: "Prs",
    COM: "Com",
    DEX: "Dex",
    QIK: "Qik",
}

export const characteristics = [
    characteristicsEnum.INT,
    characteristicsEnum.PER,
    characteristicsEnum.STR,
    characteristicsEnum.STA,
    characteristicsEnum.PRS,
    characteristicsEnum.COM,
    characteristicsEnum.DEX,
    characteristicsEnum.QIK,
]

export const techniquesEnum = {
    CR: "Cr",
    IN: "In",
    MU: "Mu",
    PE: "Pe",
    RE: "Re",
}

export const techniques = [
    techniquesEnum.CR,
    techniquesEnum.IN,
    techniquesEnum.MU,
    techniquesEnum.PE,
    techniquesEnum.RE,
]

export const formsEnum = {
    AN: "An",
    AQ: "Aq",
    AU: "Au",
    CO: "Co",
    HE: "He",
    IG: "Ig",
    IM: "Im",
    ME: "Me",
    TE: "Te",
    VI: "Vi",
}

export const forms = [
    formsEnum.AN,
    formsEnum.AQ,
    formsEnum.AU,
    formsEnum.CO,
    formsEnum.HE,
    formsEnum.IG,
    formsEnum.IM,
    formsEnum.ME,
    formsEnum.TE,
    formsEnum.VI,
]

export const artsEnum = { ...techniquesEnum, ...formsEnum }

export const arts = [...techniques, ...forms]

export const wordsEnum = {
    LOUD: "Loud",
    FIRM: "Firm",
    QUIET: "Quiet",
    NONE: "None",
}

export const words = [
    wordsEnum.LOUD,
    wordsEnum.FIRM,
    wordsEnum.QUIET,
    wordsEnum.NONE,
]

export const wordsModifiers = [
    { type: wordsEnum.LOUD, modifier: 1 },
    { type: wordsEnum.FIRM, modifier: 0 },
    { type: wordsEnum.QUIET, modifier: -5 },
    { type: wordsEnum.NONE, modifier: -10 },
]

export const gesturesEnum = {
    EXAGGERATED: "Exaggerated",
    BOLD: "Bold",
    SUBTLE: "Subtle",
    NONE: "None",
}

export const gesturesModifiers = [
    { type: gesturesEnum.EXAGGERATED, modifier: 1 },
    { type: gesturesEnum.BOLD, modifier: 0 },
    { type: gesturesEnum.SUBTLE, modifier: -2 },
    { type: gesturesEnum.NONE, modifier: -5 },
]

export const gestures = [
    gesturesEnum.EXAGGERATED,
    gesturesEnum.BOLD,
    gesturesEnum.SUBTLE,
    gesturesEnum.NONE,
]

export const characterTypesEnum = {
    MAGUS: "Magus",
    COMPANION: "Companion",
    GROG: "Grog",
}

export const characterTypes = [
    characterTypesEnum.MAGUS,
    characterTypesEnum.COMPANION,
    characterTypesEnum.GROG,
]

export const fatigueTypesEnum = {
    LONG: "Long",
    SHORT: "Short",
}

export const fatigueTypes = [fatigueTypesEnum.LONG, fatigueTypesEnum.SHORT]

export const modifyFatigueActionsEnum = {
    ADD: "add",
    SUBTRACT: "subtract",
    FULLREST: "full-rest",
}

export const fatigueDescriptionsEnum = {
    FRESH: "Fresh",
    WINDED: "Winded",
    WEARY: "Weary",
    TIRED: "Tired",
    DAZED: "Dazed",
    UNCONSCIOUS: "Unconscious",
}

export const fatigueDescriptions = [
    fatigueDescriptionsEnum.FRESH,
    fatigueDescriptionsEnum.WINDED,
    fatigueDescriptionsEnum.WEARY,
    fatigueDescriptionsEnum.TIRED,
    fatigueDescriptionsEnum.DAZED,
    fatigueDescriptionsEnum.UNCONSCIOUS,
]

export const fatigueModifiers = [
    { type: fatigueDescriptionsEnum.FRESH, modifier: 0 },
    { type: fatigueDescriptionsEnum.WINDED, modifier: 0 },
    { type: fatigueDescriptionsEnum.WEARY, modifier: -1 },
    { type: fatigueDescriptionsEnum.TIRED, modifier: -3 },
    { type: fatigueDescriptionsEnum.DAZED, modifier: -5 },
    { type: fatigueDescriptionsEnum.UNCONSCIOUS, modifier: 0 },
]

export const woundTypes = {
    LIGHT: "Light",
    MEDIUM: "Medium",
    HEAVY: "Heavy",
    INCAPACITATING: "Incapacitating",
    DEAD: "Dead",
}

export const woundsModifiers = [
    {
        type: woundTypes.LIGHT,
        rank: 0,
        modifier: -1,
        recoveryModifier: 0,
        recoveryInterval: "One Week",
        stableEaseFactor: 4,
        improvementEaseFactor: 10,
    },
    {
        type: woundTypes.MEDIUM,
        rank: 1,
        modifier: -3,
        recoveryModifier: 0,
        recoveryInterval: "One Month",
        stableEaseFactor: 6,
        improvementEaseFactor: 12,
    },
    {
        type: woundTypes.HEAVY,
        rank: 2,
        modifier: -5,
        recoveryModifier: 0,
        recoveryInterval: "One Season",
        stableEaseFactor: 9,
        improvementEaseFactor: 15,
    },
    {
        type: woundTypes.INCAPACITATING,
        rank: 3,
        modifier: null,
        recoveryModifier: 0,
        recoveryInterval: "Twice Per Day",
        stableEaseFactor: null,
        improvementEaseFactor: null,
    },
    {
        type: woundTypes.DEAD,
        rank: 4,
        modifier: 0,
        recoveryModifier: 0,
        recoveryInterval: "Never",
        stableEaseFactor: null,
        improvementEaseFactor: null,
    },
]

export const woundStatesEnum = {
    CONSCIOUS: "Conscious",
    INCAPACITATED: "Incapacitated",
    DEAD: "Dead",
}

export const modifyWoundsActionsEnum = {
    ADD: "add",
    RECOVERY: "recovery",
}

export const scuffleEffects = {
    [woundTypes.LIGHT]: "1 Fatigue",
    [woundTypes.MEDIUM]: "2 Fatigue",
    [woundTypes.HEAVY]: "2 Fatigue, Light Wound",
    [woundTypes.INCAPACITATING]: "3 Fatigue, Light Wound",
    [woundTypes.DEAD]: "5 Fatigue, Medium Wound",
}

export const actionTypesEnum = {
    ABILITY: "ability",
    MAGIC: "magic",
    COMBAT: "combat",
    DICE: "dice",
    FATIGUE: "fatigue",
    WOUNDS: "wounds",
    ENTITIES: "entities",
}

export const resultTypesEnum = {
    ABILITY: "ability",
    SPELL: "spell",
    AIMING: "aiming",
    COMBAT: "combat",
    INITIATIVE: "initiative",
    DICE: "dice",
    FATIGUE: "fatigue",
    WOUNDS: "wounds",
    INSTANTIATION: "instantiation",
    DELETE: "delete",
    UPDATE: "update",
}

export const abilitiesEnum = {
    CONCENTRATION: "Concentration",
    PENETRATION: "Penetration",
    ARTES_LIBERALES: "Artes Liberales",
    PHILOSOPHIAE: "Philosophiae",
    FINESSE: "Finesse",
    AWARENESS: "Awareness",
    RIDE: "Ride",
    MEDICINE: "Medicine",
    CHIRURGY: "Chirurgy",
}

export const virtuesEnum = {
    ENDURING_CONSTITUTION: "Enduring Constitution",
    FAST_CASTER: "Fast Caster",
    RAPID_CONVALESCENCE: "Rapid Convalescence",
}

export const medicAbilities = [abilitiesEnum.MEDICINE, abilitiesEnum.CHIRURGY]

export const magicTypesEnum = {
    RITUAL: "Ritual",
    FORMULAIC: "Formulaic",
    SPONTANEOUS_FATIGUING: "Spontaneous (Fatiguing)",
    SPONTANEOUS_NONFATIGUING: "Spontaneous (Non-Fatiguing)",
}

export const spontaneousMagicTypes = [
    magicTypesEnum.SPONTANEOUS_FATIGUING,
    magicTypesEnum.SPONTANEOUS_NONFATIGUING,
]

export const attackResultsEnum = {
    MISS: "Miss",
    HIT: "Hit",
}

export const initiativeCheckTypesEnum = {
    WEAPON: "Weapon",
    SPELL: "Spell",
}

export const unarmedWeapons = [
    {
        name: "Fist",
        ability: "Brawl (Fist)",
        initiative: 0,
        attack: 0,
        defense: 0,
        damage: 0,
        range: 0,
        minimumStrength: null,
        load: 0,
        shield: false,
    },
    {
        name: "Kick",
        ability: "Brawl (Kick)",
        initiative: -1,
        attack: 0,
        defense: -1,
        damage: 3,
        range: 0,
        minimumStrength: null,
        load: 0,
        shield: false,
    },
    {
        name: "Gauntlet",
        ability: "Brawl (Fist)",
        initiative: 0,
        attack: 0,
        defense: 1,
        damage: 2,
        range: 0,
        minimumStrength: -3,
        load: 0,
        shield: false,
    },
    {
        name: "Bludgeon",
        ability: "Brawl (Bludgeon)",
        initiative: 0,
        attack: 2,
        defense: 0,
        damage: 2,
        range: 0,
        minimumStrength: -2,
        load: 1,
        shield: false,
    },
    {
        name: "Thrown Object",
        ability: "Thrown Weapon (Object)",
        initiative: 0,
        attack: 1,
        defense: 0,
        damage: 2,
        range: 5,
        minimumStrength: -1,
        load: 1,
        shield: false,
    },
]

export const meleeWeapons = [
    {
        name: "Dagger",
        ability: "Brawl (Dagger)",
        initiative: 0,
        attack: 2,
        defense: 0,
        damage: 3,
        range: 0,
        minimumStrength: -3,
        load: 0,
        shield: false,
    },
    {
        name: "Long Sword",
        ability: "Single Weapon (Long Sword)",
        initiative: 2,
        attack: 4,
        defense: 1,
        damage: 6,
        range: 0,
        minimumStrength: 0,
        load: 1,
        shield: false,
    },
    {
        name: "Great Sword",
        ability: "Great Weapon (Great Sword)",
        initiative: 2,
        attack: 5,
        defense: 2,
        damage: 9,
        range: 0,
        minimumStrength: 1,
        load: 2,
        shield: false,
    },
    {
        name: "Lance",
        ability: "Single Weapon (Lance)",
        initiative: 2,
        attack: 4,
        defense: 0,
        damage: 5,
        range: 0,
        minimumStrength: 0,
        load: 2,
        shield: false,
    },
    {
        name: "Short Sword",
        ability: "Single Weapon (Short Sword)",
        initiative: 1,
        attack: 3,
        defense: 1,
        damage: 5,
        range: 0,
        minimumStrength: -1,
        load: 1,
        shield: false,
    },
]

export const missileWeapons = [
    {
        name: "Short Bow",
        ability: "Bows (Short Bow)",
        initiative: -1,
        attack: 3,
        defense: 0,
        damage: 6,
        range: 15,
        minimumStrength: -1,
        load: 2,
        shield: false,
    },
    {
        name: "Long Bow",
        ability: "Bows (Long Bow)",
        initiative: -2,
        attack: 4,
        defense: 0,
        damage: 8,
        range: 30,
        minimumStrength: 2,
        load: 2,
        shield: false,
    },
]

export const shields = [
    {
        name: "Heater Shield",
        ability: null,
        initiative: 0,
        attack: 0,
        defense: 3,
        damage: 0,
        range: 0,
        minimumStrength: 0,
        load: 2,
        shield: true,
    },
    {
        name: "Round Shield",
        ability: "Single Weapon (Round Shield)",
        initiative: 0,
        attack: 0,
        defense: 2,
        damage: 0,
        range: 0,
        minimumStrength: -1,
        load: 2,
        shield: true,
    },
]

export const unarmoredArmor = { name: "None", protection: 0, load: 0 }

export const armors = [
    {
        name: "Metal Reinforced Leather (Partial)",
        protection: 2,
        load: 2,
    },
    { name: "Leather Scale", protection: 5, load: 5 },
    { name: "Chain Mail", protection: 9, load: 6 },
]

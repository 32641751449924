import React, { useState, useEffect } from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { displayName, combatCheck, initiativeCheck } from "./Utils"
import { Button, Row, Column, Title, LabeledSelect, Input, Switch, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"
import { initiativeCheckTypesEnum, unarmedWeapons, unarmoredArmor } from "./Enums"

export default function CombatAction(props) {
    const { characters, openResult, closeAction } = props

    const [attackingCharacter, setAttackingCharacter] = useState(null)
    const [attackWeaponsUsed, setAttackWeaponsUsed] = useState([])
    const [attackCombatAbility, setAttackCombatAbility] = useState("")
    const [attackModifier, setAttackModifier] = useState(0)
    const [rangedAttack, setRangedAttack] = useState(false)
    const [range, setRange] = useState(null)
    const [attackMounted, setAttackMounted] = useState(false)
    const [attackExertion, setAttackExertion] = useState(false)
    const [attackArmor, setAttackArmor] = useState(null)

    const [defendingCharacter, setDefendingCharacter] = useState(null)
    const [defenseWeaponsUsed, setDefenseWeaponsUsed] = useState([])
    const [defenseCombatAbility, setDefenseCombatAbility] = useState("")
    const [defenseModifier, setDefenseModifier] = useState(0)
    const [rangedDefense, setRangedDefense] = useState(false) //place to store range in case of swap
    const [defenseMounted, setDefenseMounted] = useState(false)
    const [defenseExertion, setDefenseExertion] = useState(false)
    const [defenseArmor, setDefenseArmor] = useState(null)

    const attackWeaponsAvailable = attackingCharacter
        ? [...attackingCharacter.weapons, ...unarmedWeapons]
        : []
    const defenseWeaponsAvailable = defendingCharacter
        ? [...defendingCharacter.weapons, ...unarmedWeapons]
        : []

    let attackArmorAvailable = [unarmoredArmor]
    if (attackingCharacter && attackingCharacter.armor) {
        attackArmorAvailable = [attackingCharacter.armor, unarmoredArmor]
    }
    let defenseArmorAvailable = [unarmoredArmor]
    if (defendingCharacter && defendingCharacter.armor) {
        defenseArmorAvailable = [defendingCharacter.armor, unarmoredArmor]
    }

    useEffect(() => {
        if (props.attackingCharacter) {
            setAttackingCharacter(props.attackingCharacter)
        }
    }, [])

    useEffect(() => {
        if (attackingCharacter) {
            if (
                attackingCharacter.weapons.length > 0 &&
                attackWeaponsUsed.length === 0
            ) {
                const weaponsUsed = getInitWeapons({ character: attackingCharacter })
                setAttackWeaponsUsed(weaponsUsed)
                if (weaponsUsed[0].ability) {
                    setAttackCombatAbility(weaponsUsed[0].ability)
                }
            }
            if (!attackArmor) {
                const armor = getInitArmor({ character: attackingCharacter })
                setAttackArmor(armor)
            }
        }
    }, [attackingCharacter])

    useEffect(() => {
        if (defendingCharacter) {
            if (defenseWeaponsUsed.length === 0) {
                const weaponsUsed = getInitWeapons({ character: defendingCharacter })
                setDefenseWeaponsUsed(weaponsUsed)
                if (weaponsUsed.length > 0 && weaponsUsed[0].ability) {
                    setDefenseCombatAbility(weaponsUsed[0].ability)
                }
            }
            if (!defenseArmor) {
                const armor = getInitArmor({ character: defendingCharacter })
                setDefenseArmor(armor)
            }
        }
    }, [defendingCharacter])

    useEffect(() => {
        if (attackWeaponsUsed.length === 0) {
            setAttackCombatAbility("")
        } else {
            const availableAttackCombatAbilities = attackWeaponsUsed.map(el => el.ability)
            if (attackCombatAbility && !availableAttackCombatAbilities.includes(attackCombatAbility)) {
                setAttackCombatAbility(attackWeaponsUsed[0].ability)
            } else if (!attackCombatAbility) {
                setAttackCombatAbility(attackWeaponsUsed[0].ability)
            }
            if (attackWeaponsUsed[0].range > 0) {
                setRangedAttack(true)
            } else {
                setRangedAttack(false)
            }
        }
    }, [attackWeaponsUsed])

    useEffect(() => {
        if (defenseWeaponsUsed.length === 0) {
            setDefenseCombatAbility("")
        } else {
            const availableDefenseCombatAbilities = defenseWeaponsUsed.map(el => el.ability)
            if (defenseCombatAbility && !availableDefenseCombatAbilities.includes(defenseCombatAbility)) {
                setDefenseCombatAbility(defenseWeaponsUsed[0].ability)
            } else if (!defenseCombatAbility) {
                setDefenseCombatAbility(defenseWeaponsUsed[0].ability)
            }
        }
    }, [defenseWeaponsUsed])

    const handleCombat = () => {
        const result = combatCheck({
            attackingCharacter,
            attackCombatAbility,
            attackExertion,
            attackMounted,
            attackWeapons: attackWeaponsUsed,
            attackModifier,
            rangedAttack,
            range,
            defendingCharacter,
            defenseCombatAbility,
            defenseExertion,
            defenseMounted,
            defenseModifier,
            defenseWeapons: defenseWeaponsUsed,
            armor: defenseArmor,
        })
        openResult(result)
    }

    const handleAttackingCharacterChange = (newAttackingCharacter) => {
        setAttackingCharacter(newAttackingCharacter)
        const weaponsUsed = getInitWeapons({ character: newAttackingCharacter })
        setAttackWeaponsUsed(weaponsUsed)
        const armor = getInitArmor({ character: newAttackingCharacter })
        setAttackArmor(armor)
    }

    const handleDefendingCharacterChange = (newDefendingCharacter) => {
        setDefendingCharacter(newDefendingCharacter)
        const weaponsUsed = getInitWeapons({ character: newDefendingCharacter })
        setDefenseWeaponsUsed(weaponsUsed)
        const armor = getInitArmor({ character: newDefendingCharacter })
        setDefenseArmor(armor)
    }

    const getInitWeapons = ({ character }) => {
        const { weapons } = character
        const availableNonShieldWeapons = weapons.filter(
            (el) => el.shield === false
        )
        const availableShields = weapons.filter((el) => el.shield === true)
        let initWeapons = []
        if (availableNonShieldWeapons.length > 0) {
            initWeapons.push(availableNonShieldWeapons[0])
        }
        if (availableShields.length > 0) {
            initWeapons.push(availableShields[0])
        }
        return initWeapons
    }

    const getInitArmor = ({ character }) => {
        const { armor } = character
        if (armor) {
            return character.armor
        } else {
            return unarmoredArmor
        }
    }

    const swap = () => {
        const newAttackingCharacter = defendingCharacter
        const newAttackCombatAbility = defenseCombatAbility
        const newAttackWeaponsUsed = defenseWeaponsUsed
        const newAttackModifier = defenseModifier
        const newAttackMounted = defenseMounted
        const newAttackExertion = defenseExertion
        const newRangedAttack = rangedDefense
        const newAttackArmor = defenseArmor

        const newDefendingCharacter = attackingCharacter
        const newDefenseCombatAbility = attackCombatAbility
        const newDefenseWeaponsUsed = attackWeaponsUsed
        const newDefenseModifier = attackModifier
        const newDefenseMounted = attackMounted
        const newDefenseExertion = attackExertion
        const newRangedDefense = rangedAttack
        const newDefenseArmor = attackArmor

        setAttackingCharacter(newAttackingCharacter)
        setAttackCombatAbility(newAttackCombatAbility)
        setAttackWeaponsUsed(newAttackWeaponsUsed)
        setAttackModifier(newAttackModifier)
        setAttackMounted(newAttackMounted)
        setAttackExertion(newAttackExertion)
        setRangedAttack(newRangedAttack)
        setAttackArmor(newAttackArmor)

        setDefendingCharacter(newDefendingCharacter)
        setDefenseCombatAbility(newDefenseCombatAbility)
        setDefenseWeaponsUsed(newDefenseWeaponsUsed)
        setDefenseModifier(newDefenseModifier)
        setDefenseMounted(newDefenseMounted)
        setDefenseExertion(newDefenseExertion)
        setRangedDefense(newRangedDefense)
        setDefenseArmor(newDefenseArmor)
    }

    const handleInitiativeCheck = () => {
        const result = initiativeCheck({
            type: initiativeCheckTypesEnum.WEAPON,
            character: attackingCharacter,
            weapon: attackWeaponsUsed.filter((weapon) => !weapon.sheild)[0],
        })
        openResult(result)
    }

    const defenseWeaponComponent = defendingCharacter ? (
        <LabeledSelect
            direction="column"
            multiple={true}
            options={defenseWeaponsAvailable}
            optionFunc={(el) => el.name}
            label="Defense Weapons"
            value={defenseWeaponsUsed.map((weapon) => weapon.name)}
            error={!defenseWeaponsUsed}
            setter={(value) => {
                setDefenseWeaponsUsed(
                    defenseWeaponsAvailable.filter((weapon) =>
                        value.includes(weapon.name)
                    )
                )
            }}
        />
    ) : null

    const attackCombatAbilityComponent =
        attackWeaponsUsed && attackWeaponsUsed.length > 0 ? (
            <LabeledSelect
                direction="column"
                options={attackWeaponsUsed.map((weapon) => weapon.ability)}
                label="Attack Combat Ability"
                value={attackCombatAbility}
                error={!attackCombatAbility}
                setter={setAttackCombatAbility}
            />
        ) : null

    const defenseCombatAbilityComponent =
        defenseWeaponsUsed && defenseWeaponsUsed.length > 0 ? (
            <LabeledSelect
                direction="column"
                options={defenseWeaponsUsed.map((weapon) => weapon.ability)}
                label="Attack Combat Ability"
                value={defenseCombatAbility}
                error={!defenseCombatAbility}
                setter={setDefenseCombatAbility}
            />
        ) : null

    const rangeComponent = rangedAttack ? (
        <Input
            label="Range (Paces)"
            value={range}
            error={range === null}
            setter={setRange}
        />
    ) : null

    const attackArmorComponent = !attackArmor ? null : (
        <LabeledSelect
            direction="column"
            options={attackArmorAvailable}
            optionFunc={(el) => el.name}
            label="Attack Armor"
            value={attackArmor.name}
            error={!attackArmor}
            setter={(value) => {
                setAttackArmor(
                    attackArmorAvailable.filter((armor) =>
                        armor.name === value)[0]
                )
            }}
        />
    )

    const defenseArmorComponent = !defenseArmor ? null : (
        <LabeledSelect
            direction="column"
            options={defenseArmorAvailable}
            optionFunc={(el) => el.name}
            label="Defense Armor"
            value={defenseArmor.name}
            error={!defenseArmor}
            setter={(value) => {
                setDefenseArmor(
                    defenseArmorAvailable.filter((armor) =>
                        armor.name === value)[0]
                )
            }}
        />
    )

    const attackReady =
        attackingCharacter &&
        attackCombatAbility &&
        attackWeaponsUsed &&
        defendingCharacter &&
        (!rangedAttack || range !== null)

    const initiativeReady =
        attackingCharacter &&
        attackWeaponsUsed.filter((weapon) => !weapon.sheild).length !== 0

    return (
        <>
            <ActionTitleArea>
                <Title>Combat</Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    <Column style={{ gap: "0px" }}>
                        <div>Attacking Party</div>
                        <Select
                            id="attacking-character"
                            size="small"
                            value={attackingCharacter ? displayName(attackingCharacter) : ""}
                            error={!attackingCharacter}
                        >
                            {characters.map((character) => {
                                return (<MenuItem value={displayName(character)} onClick={() => { handleAttackingCharacterChange(character) }}>{displayName(character)}</MenuItem>)
                            })}
                        </Select>
                    </Column>
                    <LabeledSelect
                        direction="column"
                        multiple={true}
                        options={attackWeaponsAvailable}
                        optionFunc={(el) => el.name}
                        label="Attack Weapons"
                        value={attackWeaponsUsed.map((weapon) => weapon.name)}
                        error={!attackWeaponsUsed}
                        setter={(value) => {
                            setAttackWeaponsUsed(
                                attackWeaponsAvailable.filter((weapon) =>
                                    value.includes(weapon.name)
                                )
                            )
                        }}
                    />
                    {attackCombatAbilityComponent}
                    {attackArmorComponent}
                    <Switch label="Ranged Attack" checked={rangedAttack} setter={setRangedAttack} />
                    {rangeComponent}
                    <Row>
                        <Switch label="Attack Exertion" checked={attackExertion} setter={setAttackExertion} />
                        <Switch label="Attack Mounted" checked={attackMounted} setter={setAttackMounted} />
                    </Row>
                    <Input label="Attack Modifier" value={attackModifier} setter={setAttackModifier} />
                    <Column style={{ gap: "0px" }}>

                        <div>Defending Party</div>
                        <Select
                            id="defending-character"
                            size="small"
                            value={defendingCharacter ? displayName(defendingCharacter) : ""}
                            error={!defendingCharacter}
                        >
                            {characters.map((character) => (
                                <MenuItem value={displayName(character)} onClick={() => { handleDefendingCharacterChange(character) }}>{displayName(character)}</MenuItem>
                            ))}
                        </Select>
                    </Column>
                    {defenseWeaponComponent}
                    {defenseCombatAbilityComponent}
                    {defenseArmorComponent}
                    <Row>
                        <Switch label="Defense Exertion" checked={defenseExertion} setter={setDefenseExertion} />
                        <Switch label="Defense Mounted" checked={defenseMounted} setter={setDefenseMounted} />

                    </Row>
                    <Input label="Defense Modifier" value={defenseModifier} setter={setDefenseModifier} />
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Row>
                    <Button disabled={!attackReady} onClick={handleCombat}>
                        Attack
                    </Button>
                    <Button onClick={swap}>Swap</Button>
                    <Button onClick={handleInitiativeCheck}>Initiative</Button>
                    <Button disabled={!initiativeReady} onClick={closeAction}>Cancel</Button>
                </Row>
            </ActionButtonsArea>
        </>
    )
}

import { useState, useEffect } from "react"
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom"
import Typography from "@mui/material/Typography"
import CssBaseline from "@mui/material/CssBaseline"
import Paper from "@mui/material/Paper"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./Styling"
import { handleLoading, handleUploading } from "./Utils"
import { url } from "./Network"
import StorytellerView from "./StorytellerView"
import CharacterSheet from "./CharacterSheet"
import arsMagicaLogo from "./arm5-logo.png"
import berrugueteOrdeal from "./Berruguete_ordeal_cropped.png"
import { Button, Grid, BannerArea, MainArea, LabeledSelect, Row, Column } from "./Components"
import { entityTypesEnum, entityRouteActionsEnum } from "./Enums"

export default function App(props) {

    const [characters, setCharacters] = useState([])
    const [npcs, setNpcs] = useState([])
    const [campaign, setCampaign] = useState(null)
    const [templates, setTemplates] = useState(null)

    useEffect(() => {
        handleLoadingEntities()
        handleLoadingTemplates()
    }, [])

    useEffect(() => {
        console.log("characters:", characters)
    }, [characters])

    useEffect(() => {
        console.log("npcs:", npcs)
    }, [npcs])

    useEffect(() => {
        console.log("campaign:", campaign)
    }, [campaign])

    useEffect(() => {
        console.log("templates:", templates)
    }, [templates])

    const handleLoadingTemplates = () => {
        handleLoading({
            requestStrings: [`${url}/templates?`],
            handleData: (newData) => {
                const newObjects = newData[0].map(el => {
                    return { templateId: el.id, ...el.template }
                })
                setTemplates(newObjects)
            },
        })
    }

    const handleLoadingEntities = () => {
        handleLoading({
            requestStrings: [`${url}/entities?`],
            handleData: (newData) => {
                const newObjects = newData[0].map(el => el.entity)
                const newCharacters = newObjects.filter(el => el.type === entityTypesEnum.CHARACTER).sort((a, b) => a.name > b.name)
                const newNpcs = newObjects.filter(el => el.type === entityTypesEnum.NPC).sort((a, b) => a.name > b.name)
                const newCampaign = newObjects.filter(el => el.type === entityTypesEnum.CAMPAIGN)[0]
                setCharacters(newCharacters)
                setNpcs(newNpcs)
                setCampaign(newCampaign)
            },
        })
    }

    const updateCharacter = ({ newCharacter }) => {
        handleUploading({
            requestString: `${url}/entities?action=${entityRouteActionsEnum.UPDATE}`,
            body: newCharacter,
            handleData: (newData) => {
                console.log(newData)
                handleLoadingEntities()
            },
        })
    }


    const storytellerViewProps = {
        campaign,
        characters: [...characters, ...npcs],
        templates,
        updateCharacter,
        handleLoadingEntities,
    }
    const storytellerView = !characters || !campaign ? null : <StorytellerView {...storytellerViewProps} />

    const characterSheetComponentProps = {
        campaign,
        characters
    }
    const characterSheetComponent = !characters || !campaign ? null : <CharacterSheet {...characterSheetComponentProps} />

    const Logo = () => {
        const navigate = useNavigate()
        const onClick = () => {
            console.log("onClick")
            navigate("/")
        }
        return (
            <Row style={{ alignContent: "center" }}>
                <div onClick={onClick} style={{ display: "grid", alignContent: "center", cursor: "pointer", marginLeft: "16px" }}>
                    <img src={arsMagicaLogo} alt="Ars Magica Logo" style={{ height: "40px" }} />
                </div>
                <div style={{ fontSize: "24px" }}>Cathar Campaign</div>
            </Row>
        )
    }

    const StorytellerButton = () => {
        const navigate = useNavigate()
        const onClick = () => { navigate("storyteller") }
        return <Button style={{ marginRight: "16px" }} onClick={onClick}>Storyteller</Button>
    }

    const Welcome = () => {
        const [player, setPlayer] = useState(null)
        const navigate = useNavigate()
        const handlePlayerChange = (player) => {
            if (player === "All") {
                navigate("characters")
            } else {
                navigate(`player/${player.toLowerCase()}`)
            }
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                <Paper style={{ padding: "24px" }}>
                    <Row>
                        <Column>
                            <img
                                src={berrugueteOrdeal}
                                alt="Berruguete Ordeal"
                                style={{ height: "500px" }}
                            />
                        </Column>
                        <Column style={{ width: "300px", alignItems: "center" }}>
                            <img
                                src={arsMagicaLogo}
                                alt="Ars Magica Logo"
                                style={{ height: "80px" }}
                            />
                            <div style={{ fontSize: "36px" }}>Cathar Campaign</div>
                            {!campaign ? null : (<div style={{ fontSize: "24px" }}>{`Current Year: ${campaign.year} A.D.`}</div>)}
                            {!campaign ? null : <LabeledSelect direction="row" options={["All", ...campaign.players]} label="Show Characters for" value={player} setter={(value) => { setPlayer(value); handlePlayerChange(value) }} style={{ width: "120px" }} />}
                        </Column>
                    </Row>
                </Paper>
            </div>
        )
    }

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Typography>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Grid>
                            <BannerArea>
                                <Row style={{ justifyContent: "space-between" }}>
                                    <Logo />
                                    <StorytellerButton />
                                </Row>
                            </BannerArea>
                            <MainArea>
                                <Routes>
                                    <Route path="/" element={<Welcome />} />
                                    <Route path="storyteller" element={storytellerView} />
                                    <Route path="characters" element={characterSheetComponent} />
                                    <Route path="character/:id" element={characterSheetComponent} />
                                    <Route path="player/:player" element={characterSheetComponent} />
                                </Routes>
                            </MainArea>
                        </Grid>
                    </div>
                </Typography>
            </ThemeProvider >
        </Router >
    )
}

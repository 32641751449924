import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import { Button, Row, Column, LabeledSelect, Title, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"
import { url } from "./Network"
import { handleUploading, handleDeleting, displayName } from "./Utils"
import { entityRouteActionsEnum, entityTypesEnum, resultTypesEnum } from "./Enums"

export default (props) => {

    const { entities, templates, handleLoadingEntities, handleUpdateCharacter, openResult, closeAction } = props

    const availableTemplates = templates.filter(template => template.type === entityTypesEnum.NPC)

    const [template, setTemplate] = useState(null)
    const [entity, setEntity] = useState(null)
    const [entityText, setEntityText] = useState("")

    useEffect(() => {
        if (!template || !availableTemplates.map(el => el.templateId).includes(template.templateId)) {
            setTemplate(availableTemplates[0])
        }
        if (!entity || !entities.map(el => el.id).includes(entity.id)) {
            setEntity(entities[0])
            setEntityText(JSON.stringify(entities[0], null, 2))
        }
    }, [templates, entities])

    useEffect(() => {
        setEntityText(JSON.stringify(entity, null, 2))
    }, [entity])

    const handleInstantiation = ({ templateId }) => {
        handleUploading({
            requestString: `${url}/entities?action=${entityRouteActionsEnum.INSTANTIATE}&templateId=${templateId}`,
            handleData: (newData) => {
                const newEntity = newData
                console.log(newEntity)
                const result = { type: resultTypesEnum.INSTANTIATION, values: { newEntity } }
                openResult(result)
                handleLoadingEntities()
            }
        })
    }

    const handleDeleteEntities = ({ entity, template }) => {
        let param = ""
        let entityId = null
        let templateId = null
        if (entity) {
            entityId = entity.id
            param = `entityId=${entityId}`
        } else if (template) {
            templateId = template.templateId
            param = `templateId=${templateId}`
        }
        handleDeleting({
            requestString: `${url}/entities?${param}`,
            handleData: (newData) => {
                console.log(newData)
                const result = { type: resultTypesEnum.DELETE, values: { entity, entityId, template, templateId } }
                openResult(result)
                handleLoadingEntities()
            }
        })
    }

    const handleUpdateEntity = ({ entityText }) => {
        try {
            const newCharacter = JSON.parse(entityText)
            handleUpdateCharacter({ newCharacter })
        } catch (error) {
            console.log(error)
            setEntityText("Error parsing text")
        }
    }

    const templateSelectionComponent = !template ? null : (
        <LabeledSelect direction="column" options={availableTemplates} optionFunc={el => el.name} label="Template" value={template.name} setter={(value) => setTemplate(availableTemplates.filter((template) => template.name === value)[0])} />
    )

    const entitySelectionComponent = (
        <LabeledSelect direction="column" options={entities} optionFunc={el => displayName(el)} label="Entity" value={!entity ? "" : displayName(entity)} setter={(value) => setEntity(entities.filter((entity) => displayName(entity) === value)[0])} />
    )

    const entityEditComponent = !entity ? null : (
        < TextField multiline id="outlined-basic" label="" variant="outlined" value={entityText} onChange={(event) => { setEntityText(event.target.value) }} sx={{ overflowY: "auto" }} />
    )

    return (
        <>
            <ActionTitleArea style={{ width: "400px" }}>
                <Title>Entities</Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    {templateSelectionComponent}
                    <Row>
                        <Button disabled={!template} onClick={() => { handleInstantiation({ templateId: template.templateId }) }}>Instantiate</Button>
                        <Button style={{ width: "130px" }} disabled={!template} onClick={() => { handleDeleteEntities({ template }) }}>Delete All</Button>
                    </Row>
                    {entitySelectionComponent}
                    <Row>
                        <Button style={{ width: "100px" }} disabled={!entity} onClick={() => { handleUpdateEntity({ entityText }) }}>Update</Button>
                        <Button style={{ width: "100px" }} disabled={!entity || entity.type !== entityTypesEnum.NPC} onClick={() => { handleDeleteEntities({ entity }) }}>Delete</Button>
                    </Row>
                    {entityEditComponent}
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Row>
                    <Button onClick={closeAction}>Cancel</Button>
                </Row>
            </ActionButtonsArea>
        </>
    )
}
import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { spellCheck, aimingCheck, parseNum, initiativeCheck } from "./Utils"
import { techniques, forms, words as wordsOptions, gestures as gesturesOptions, spontaneousMagicTypes, magicTypesEnum, initiativeCheckTypesEnum } from "./Enums"
import { Button, Row, Column, LabeledSelect, Input, Switch, Title, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"

export default (props) => {
    let { character, spell, openResult, closeAction } = props

    const [spellName, setSpellName] = useState("")
    const [technique, setTechnique] = useState("")
    const [form, setForm] = useState("")
    const [level, setLevel] = useState("")
    const [techniqueRequisites, setTechniqueRequisites] = useState([])
    const [formRequisites, setFormRequisites] = useState([])
    const [magicType, setMagicType] = useState("")
    const [fastCasting, setFastCasting] = useState(false)
    const [ceremonialCasting, setCeremonialCasting] = useState(false)
    const [numPawns, setNumPawns] = useState(0)
    const [words, setWords] = useState("Firm")
    const [gestures, setGestures] = useState("Bold")
    const [auraModifier, setAuraModifier] = useState(0)
    const [penetrationModifier, setPenetrationModifier] = useState(0)
    const [mustConcentrate, setMustConcentrate] = useState(false)
    const [concentrationEaseFactor, setConcentrationEaseFactor] = useState(0)
    const [modifier, setModifier] = useState(0)
    const [stress, setStress] = useState(false)
    const [numBotchDice, setNumBotchDice] = useState(1)

    useEffect(() => {
        if (spontaneousMagicTypes.includes(magicType)) {
            setSpellName("Spontaneous")
        } else {
            setFastCasting(false)
            setCeremonialCasting(false)
        }
    }, [magicType])

    useEffect(() => {
        if (fastCasting) {
            setCeremonialCasting(false)
        }
    }, [fastCasting])

    useEffect(() => {
        if (ceremonialCasting) {
            setFastCasting(false)
        }
    }, [ceremonialCasting])

    useEffect(() => {
        setSpellName(spell.name)
        setTechnique(spell.technique)
        setForm(spell.form)
        setLevel(spell.level)
        setTechniqueRequisites(spell.requisites.techniques)
        setFormRequisites(spell.requisites.forms)
        if (spell.name === "Spontaneous") {
            setMagicType(magicTypesEnum.SPONTANEOUS_FATIGUING)
        } else if (spell.ritual) {
            setMagicType(magicTypesEnum.RITUAL)
        } else {
            setMagicType(magicTypesEnum.FORMULAIC)
        }
    }, [spell])

    const handleSpellCheck = () => {

        const result = spellCheck({
            character,
            spell: { name: spellName, technique, form, level, requisites: { techniques: techniqueRequisites, forms: formRequisites } },
            magicType,
            fastCasting,
            ceremonialCasting,
            pawns: { number: numPawns, art: spell.form },
            words,
            gestures,
            auraModifier,
            penetrationModifier,
            mustConcentrate,
            concentrationEaseFactor,
            modifier,
            stress,
            numBotchDice: parseNum(numBotchDice)
        })
        openResult(result)
    }

    const handleAimingCheck = () => {
        const result = aimingCheck({ character, stress, numBotchDice })
        openResult(result)
    }

    const handleInitiativeCheck = () => {
        const result = initiativeCheck({
            type: initiativeCheckTypesEnum.SPELL,
            character,
            weapon: null
        })
        openResult(result)
    }

    const artsComponent = (
        <>
            <Row>
                <LabeledSelect direction="column" options={techniques} label="Technique" value={technique} setter={setTechnique} error={!technique} style={{ width: "80px" }} />
                <LabeledSelect direction="column" options={forms} label="Form" value={form} setter={setForm} error={!form} style={{ width: "80px" }} />
                <Input direction="column" label="Spell Level" value={level} error={!level} setter={setLevel} />
            </Row>
            <Row>
                <LabeledSelect direction="column" multiple={true} options={techniques} label="Technique Reqs" value={techniqueRequisites} setter={setTechniqueRequisites} style={{ width: "160px" }} />
                <LabeledSelect direction="column" multiple={true} options={forms} label="Form Reqs" value={formRequisites} setter={setFormRequisites} style={{ width: "160px" }} />
            </Row>
        </>
    )

    const wordsComponent = magicType && magicType !== "ritual" ? (
        <LabeledSelect direction="row" options={wordsOptions} label="Words" value={words} setter={setWords} />
    ) : null

    const gesturesComponent = magicType && magicType !== "ritual" ? (
        <LabeledSelect direction="row" options={gesturesOptions} label="Gestures" value={gestures} setter={setGestures} />
    ) : null

    const concentrationEaseFactorComponent = mustConcentrate ? (
        < div >
            <TextField id="concentration-ease-factor" value={concentrationEaseFactor} label="Concentration Ease Factor" variant="outlined" size="small" onChange={(event) => { setConcentrationEaseFactor(parseNum(event.target.value)) }} />
        </div >
    ) : null

    const spontaneousMagicOptionsComponent = (
        <Row>
            <Switch label="Fast Casting" checked={fastCasting} setter={setFastCasting} disabled={!spontaneousMagicTypes.includes(magicType)} />
            <Switch label="Ceremonial Casting" checked={ceremonialCasting} setter={setCeremonialCasting} disabled={!spontaneousMagicTypes.includes(magicType)} />
        </Row>
    )

    const ready = (technique) && (form) && (level) && (!mustConcentrate || concentrationEaseFactor !== undefined) && (magicType) && (!stress || numBotchDice !== undefined)

    return (
        <>
            <ActionTitleArea>
                <Title>
                    Magic
                </Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    <div>{`Spell: ${spellName}`}</div>
                    <Row>
                        <div style={{ margin: "8px 8px 8px 0px" }}>Magic Type</div>
                        <Select
                            id="magic-type"
                            size="small"
                            value={magicType}
                            helperText="Magic Type"
                            onChange={(event) => { setMagicType(event.target.value) }}
                        >
                            <MenuItem value={magicTypesEnum.FORMULAIC}>{magicTypesEnum.FORMULAIC}</MenuItem>
                            <MenuItem value={magicTypesEnum.RITUAL}>{magicTypesEnum.RITUAL}</MenuItem>
                            <MenuItem value={magicTypesEnum.SPONTANEOUS_FATIGUING}>{magicTypesEnum.SPONTANEOUS_FATIGUING}</MenuItem>
                            <MenuItem value={magicTypesEnum.SPONTANEOUS_NONFATIGUING}>{magicTypesEnum.SPONTANEOUS_NONFATIGUING}</MenuItem>
                        </Select>
                    </Row>
                    {artsComponent}
                    {spontaneousMagicOptionsComponent}
                    <Row>
                        {wordsComponent}
                        {gesturesComponent}
                    </Row>
                    <Input direction="row" label="Aura Modifier" value={auraModifier} setter={setAuraModifier} />
                    <Input direction="row" label="Penetration Modifier" value={penetrationModifier} setter={setPenetrationModifier} />
                    <Row>
                        <Switch label="Stress" checked={stress} setter={setStress} />
                        <Input direction="row" label="Botch Dice" value={numBotchDice} setter={setNumBotchDice} />
                    </Row>
                    <Switch label="Must Concentrate" checked={mustConcentrate} setter={setMustConcentrate} />
                    {concentrationEaseFactorComponent}
                    <Row>
                        <Input direction="row" label="Raw Vis Pawns" value={numPawns} setter={setNumPawns} />
                        <Input direction="row" label="Modifier" value={modifier} setter={setModifier} />
                    </Row>
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Row>
                    <Button disabled={!ready} onClick={handleSpellCheck} style={{ width: "140px" }}>Cast Spell</Button>
                    <Button onClick={handleAimingCheck} style={{ width: "100px" }}>Aiming</Button>
                    <Button onClick={handleInitiativeCheck} style={{ width: "120px" }}>Initiative</Button>
                    <Button onClick={closeAction}>Cancel</Button>
                </Row>
            </ActionButtonsArea>
        </>
    )
}

import Paper from "@mui/material/Paper"
import { TreeView, TreeItem } from "@mui/lab"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { formatModifier, getFatigueWoundsObj } from "./Utils"
import { techniques, forms, actionTypesEnum, characteristics } from "./Enums"

export default (props) => {

    const { character, year, openAction } = props
    const { fatigueDescription, fatigueModifier, fatigueRecoveryTime } = getFatigueWoundsObj({ character })

    const treeView = (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            sx={{
                overflowY: "auto",
                userSelect: "none"
            }}
        >
            {!character.might ? null : <TreeItem key="might" nodeId="might" label={`Might: ${character.might.score} (${character.might.realm}, ${character.might.form})`} />}
            {!character.age ? null : <TreeItem key="age" nodeId="age" label={`Age: ${year - character.birthYear}`} />}
            {!character.class ? null : <TreeItem key="type" nodeId="type" label={`Class: ${character.class}`} />}
            <TreeItem key="size" nodeId="size" label={`Size: ${formatModifier(character.size)}`} />
            {!character.personality ? null : <TreeItem key="personality" nodeId="personality" label="Personality">
                {Object.keys(character.personality).map((key, i) => {
                    return (
                        <TreeItem
                            key={`personality${i}`}
                            nodeId={`personality${i}`}
                            label={`${key}: ${formatModifier(
                                character.personality[key]
                            )}`}
                        />
                    )
                })}
            </TreeItem>}
            <TreeItem key="characteristics" nodeId="characteristics" label="Characteristics">
                {characteristics.map((key, i) => {
                    return (
                        <TreeItem
                            key={`characteristics${i}`}
                            nodeId={`characteristics${i}`}
                            label={`${key}: ${formatModifier(
                                character.characteristics[key].score
                            )}`}
                        />
                    )
                })}
            </TreeItem>
            <TreeItem key="fatigue" nodeId="fatigue" onClick={() => { openAction({ type: actionTypesEnum.FATIGUE, values: null }) }} label="Fatigue">
                <TreeItem key="fatigue-description" nodeId="fatigue-description" label={fatigueDescription} />
                <TreeItem key="fatigue-levels" nodeId="fatigue-levels" label={`Fatigue Levels: ${character.fatigue.length === 0 ? "None" : character.fatigue.join(", ")}`} />
                <TreeItem key="fatigue-modifier" nodeId="fatigue-modifier" label={`Modifier: ${fatigueModifier}`} />
                <TreeItem key="fatigue-recovery-time" nodeId="fatigue-recovery-time" label={`Recovery Time: ${fatigueRecoveryTime}`} />
            </TreeItem>
            <TreeItem key="wounds" nodeId="wounds" onClick={() => { openAction({ type: actionTypesEnum.WOUNDS, values: null }) }} label="Wounds">
                {character.wounds.length === 0 ? <TreeItem key="wound0" nodeId="wound0" label="None" /> : character.wounds.map((wound, i) => {
                    return <TreeItem key={`wound${i}`} nodeId={`wound${i}`} label={`${wound.type} (${wound.recoveryInterval})`} />
                })}
            </TreeItem>
            {!character.arts || Object.keys(character.arts).length === 0 ? null : <TreeItem key="arts" nodeId="arts" label="Arts">
                <TreeItem key="techniques" nodeId="techniques" label="Techniques" />
                {techniques.map((technique, i) => {
                    return <TreeItem
                        key={`techniques${i}`}
                        nodeId={`techniques${i}`}
                        label={`${technique}: ${character.arts[technique].score}`}
                        style={{ marginLeft: "16px" }}
                    />
                })}
                <TreeItem key="forms" nodeId="forms" label="Forms" />
                {forms.map((form, i) => {
                    return <TreeItem
                        key={`forms${i}`}
                        nodeId={`forms${i}`}
                        label={`${form}: ${character.arts[form].score}`}
                        style={{ marginLeft: "16px" }}
                    />
                })}
            </TreeItem>}
            {!character.virtues ? null : <TreeItem key="virtues" nodeId="virtues" label="Virtues">
                {Object.keys(character.virtues).map((key, i) => {
                    return <TreeItem key={`virtues${i}`} nodeId={`virtues${i}`} label={key} />
                })}
            </TreeItem>}
            {!character.flaws ? null : <TreeItem key="flaws" nodeId="flaws" label="Flaws">
                {Object.keys(character.flaws).map((key, i) => {
                    return <TreeItem key={`flaws${i}`} nodeId={`flaws${i}`} label={key} />
                })}
            </TreeItem>}
            {!character.abilities ? null : <TreeItem key="abilities" nodeId="abilities" label="Abilities">
                {[...Object.keys(character.abilities)].sort().map((key, i) => {
                    let ability = character.abilities[key]
                    ability["name"] = key
                    return (
                        <TreeItem
                            key={`abilities${i}`}
                            nodeId={`abilities${i}`}
                            label={`${key}: ${character.abilities[key].score}`}
                            onClick={() => openAction({ type: actionTypesEnum.ABILITY, values: { character, ability } })}
                        />
                    )
                })}
            </TreeItem>}
            {!character.spells ? null : <TreeItem key="spells" nodeId="spells" label="Spells">
                {[...Object.keys(character.spells)].sort().map((key, i) => {
                    let spell = { ...character.spells[key] }
                    spell["name"] = key
                    const requisitesLabel = (spell.requisites.techniques.length === 0 && spell.requisites.forms.length === 0)
                        ? ""
                        : ` Req: ${[...spell.requisites.techniques, ...spell.requisites.forms].join(", ")}`
                    return (
                        <TreeItem
                            key={`spells${i}`}
                            nodeId={`spells${i}`}
                            label={`${key} (${spell.technique}${spell.form} ${spell.level}${requisitesLabel})`}
                            onClick={() => { openAction({ type: actionTypesEnum.MAGIC, values: { character, spell } }) }}
                        />
                    )
                })}
            </TreeItem>}
        </TreeView>
    )

    return <Paper style={{ padding: "16px" }}>{treeView}</Paper>
}

import { createTheme } from "@mui/material/styles"

const darkMode = false
const customFont = true

export const lightColor = darkMode ? "#ffffff" : "#fdc886"
export const darkColor = darkMode ? "#181818" : "#e5b271"
export const bannerColor = darkMode ? "#ffffff" : "#cf9450"
export const lineColor = darkMode ? "#ffffff" : "#875510"
// brown #795548

const palette = darkMode
    ? {
          mode: "dark",
          background: {
              default: darkColor,
          },
      }
    : {
          mode: "light",
          background: {
              default: darkColor,
              paper: lightColor,
          },
          primary: {
              main: "#875510",
          },
      }

const typography = customFont
    ? {
          fontSize: 16,
          fontFamily: ["Pfeffer Mediaeval"],
      }
    : undefined

// const components = {
//     MuiButton: {
//         variants: [
//             {
//                 props: { variant: "outlined" },
//                 style: { border: `1.5px solid ${palette.primary.main}` },
//             },
//         ],
//     },
// }

export const theme = createTheme({
    palette,
    typography,
    // components,
})

import { useState, useEffect } from "react"
import CharacterTree from "./CharacterTree"
import MagicAction from "./MagicAction"
import SpellResult from "./SpellResult"
import AimingResult from "./AimingResult"
import AbilityAction from "./AbilityAction"
import AbilityResult from "./AbilityResult"
import CombatAction from "./CombatAction"
import CombatResult from "./CombatResult"
import InitiativeResult from "./InitiativeResult"
import DiceAction from "./DiceAction"
import DiceResult from "./DiceResult"
import FatigueAction from "./FatigueAction"
import FatigueResult from "./FatigueResult"
import WoundsAction from "./WoundsAction"
import WoundsResult from "./WoundsResult"
import EntitiesAction from "./EntitiesAction"
import UpdateResult from "./UpdateResult"
import InstantiationResult from "./InstantiationResult"
import DeleteResult from "./DeleteResult"
import {
    Button,
    Row,
    CharacterSelection,
    StorytellerGrid,
    CharacterSelectionArea,
    CharacterTreeArea,
    ButtonsArea,
    ResultButtonsArea,
} from "./Components"
import { actionTypesEnum, resultTypesEnum } from "./Enums"


export default function StorytellerView(props) {
    const { campaign, characters, templates, updateCharacter, handleLoadingEntities } = props
    const { year } = campaign

    const [action, setAction] = useState(null)
    const [result, setResult] = useState(null)
    const [character, setCharacter] = useState(null)

    useEffect(() => {
        if (!character || !characters.map(el => el.id).includes(character.id)) {
            setCharacter(characters[0])
        } else {
            setCharacter(characters.filter(el => el.id === character.id)[0])
        }
    }, [characters])

    useEffect(() => {
        console.log("character:", character)
    }, [character])

    useEffect(() => {
        setResult(null)
    }, [action])

    const closeAction = () => {
        setAction(null)
    }

    const closeResult = () => {
        setResult(null)
    }

    const openAction = (action) => {
        console.log("action:", action)
        setAction(action)
    }

    const openResult = (result) => {
        console.log("result:", result)
        setResult(result)
    }

    const handleUpdateCharacter = ({ newCharacter }) => {
        updateCharacter({ newCharacter })
        if (!result) {
            openResult({ type: resultTypesEnum.UPDATE, values: { entity: newCharacter } })
        }
    }

    let actionComponent = null
    if (action) {
        let actionProps = { ...action.values, openResult, closeAction }
        if (action.type === actionTypesEnum.ABILITY) {
            actionComponent = <AbilityAction {...actionProps} />
        } else if (action.type === actionTypesEnum.MAGIC) {
            actionComponent = <MagicAction {...actionProps} />
        } else if (action.type === actionTypesEnum.COMBAT) {
            actionComponent = <CombatAction {...actionProps} />
        } else if (action.type === actionTypesEnum.DICE) {
            actionComponent = <DiceAction {...actionProps} />
        } else if (action.type === actionTypesEnum.FATIGUE) {
            actionProps = { ...actionProps, character, handleUpdateCharacter }
            actionComponent = <FatigueAction {...actionProps} />
        } else if (action.type === actionTypesEnum.WOUNDS) {
            actionProps = {
                ...actionProps,
                character,
                characters,
                handleUpdateCharacter,
            }
            actionComponent = <WoundsAction {...actionProps} />
        } else if (action.type === actionTypesEnum.ENTITIES) {
            actionProps = { ...actionProps, templates, entities: characters, handleLoadingEntities, handleUpdateCharacter }
            actionComponent = <EntitiesAction {...actionProps} />
        }
    }

    let resultComponent = null
    if (result) {
        let resultProps = { ...result.values }
        if (result.type === resultTypesEnum.ABILITY) {
            resultComponent = <AbilityResult {...resultProps} />
        } else if (result.type === resultTypesEnum.SPELL) {
            resultComponent = <SpellResult {...resultProps} />
        } else if (result.type === resultTypesEnum.AIMING) {
            resultComponent = <AimingResult {...resultProps} />
        } else if (result.type === resultTypesEnum.COMBAT) {
            resultComponent = <CombatResult {...resultProps} />
        } else if (result.type === resultTypesEnum.INITIATIVE) {
            resultComponent = <InitiativeResult {...resultProps} />
        } else if (result.type === resultTypesEnum.DICE) {
            resultComponent = <DiceResult {...resultProps} />
        } else if (result.type === resultTypesEnum.FATIGUE) {
            resultComponent = <FatigueResult {...resultProps} />
        } else if (result.type === resultTypesEnum.WOUNDS) {
            resultComponent = <WoundsResult {...resultProps} />
        } else if (result.type === resultTypesEnum.INSTANTIATION) {
            resultComponent = <InstantiationResult {...resultProps} />
        } else if (result.type === resultTypesEnum.DELETE) {
            resultComponent = <DeleteResult {...resultProps} />
        } else if (result.type === resultTypesEnum.UPDATE) {
            resultComponent = <UpdateResult {...resultProps} />
        }
    }

    const characterSelectionProps = {
        character,
        characters,
        setCharacter,
        onChange: () => setAction(null),
    }

    const characterTreeProps = {
        character,
        year,
        openAction,
    }

    const buttons = (
        <Row>
            <Button onClick={() => openAction({ type: actionTypesEnum.ENTITIES })}>
                Entities
            </Button>
            <Button onClick={() => openAction({ type: actionTypesEnum.DICE })}>
                Dice
            </Button>
            <Button
                onClick={() =>
                    openAction({
                        type: actionTypesEnum.ABILITY,
                        values: {
                            character,
                            ability: { name: "Misc", score: 0 },
                        },
                    })
                }
            >
                Ability
            </Button>
            <Button
                onClick={() =>
                    openAction({
                        type: actionTypesEnum.COMBAT,
                        values: {
                            attackingCharacter: character,
                            characters,
                        },
                    })
                }
            >
                Combat
            </Button>
            <Button
                disabled={!character || character.class !== "Magus"}
                onClick={() =>
                    openAction({
                        type: actionTypesEnum.MAGIC,
                        values: {
                            character,
                            spell: {
                                name: "Spontaneous",
                                technique: "",
                                form: "",
                                level: "",
                                requisites: { techniques: [], forms: [] },
                            },
                        },
                    })
                }
            >
                Magic
            </Button>
        </Row>
    )

    const resultCloseButton = !result ? null : (
        <ResultButtonsArea>
            <Button onClick={closeResult}>Close</Button>
        </ResultButtonsArea>
    )



    return (
        <StorytellerGrid>
            <CharacterSelectionArea>
                <CharacterSelection {...characterSelectionProps} />
            </CharacterSelectionArea>
            <CharacterTreeArea>
                {!character ? null : <CharacterTree {...characterTreeProps} />}
            </CharacterTreeArea>
            <ButtonsArea>{buttons}</ButtonsArea>
            {actionComponent}
            {resultComponent}
            {resultCloseButton}
        </StorytellerGrid >
    )
}

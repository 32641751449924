import React, { useState, useEffect } from "react"
import { abilityCheck } from "./Utils"
import { characteristics } from "./Enums"
import { Button, Title, Row, Column, Switch, Input, LabeledSelect, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"

export default (props) => {
    const { character, ability, openResult, closeAction } = props

    const [score, setScore] = useState(0)
    const [easeFactor, setEaseFactor] = useState("")
    const [characteristic, setCharacteristic] = useState("")
    const [specialized, setSpecialized] = useState(false)
    const [modifier, setModifier] = useState(0)
    const [stress, setStress] = useState(false)
    const [numBotchDice, setNumBotchDice] = useState(1)
    const [useEncumbranceModifier, setUseEncumbranceModifier] = useState(false)

    useEffect(() => {
        setScore(ability.score)
    }, [ability])

    const handleAbilityCheck = () => {
        const result = abilityCheck({
            character,
            easeFactor,
            characteristicScore: character.characteristics[characteristic].score,
            abilityScore: score,
            specialized,
            fatigue: character.fatigue,
            wounds: character.wounds,
            modifier,
            stress,
            numBotchDice,
            useEncumbranceModifier
        })
        openResult(result)
    }

    const ready = easeFactor && characteristic

    return (
        <>
            <ActionTitleArea>
                <Title>Ability Check</Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    <div>{`Ability: ${ability.name}`}</div>
                    <Input label="Score" value={score} setter={setScore} />
                    <LabeledSelect direction="row" options={characteristics} label="Characteristic" value={characteristic} setter={setCharacteristic} error={!characteristic} style={{ width: 80 }} />
                    <Switch label="Specialized" checked={specialized} setter={setSpecialized} />
                    <Input label="Ease Factor" value={easeFactor} error={!easeFactor} setter={setEaseFactor} />
                    <Input label="Modifier" value={modifier} setter={setModifier} />
                    <Switch label="Stress" checked={stress} setter={setStress} />
                    <Input label="Botch Dice" value={numBotchDice} setter={setNumBotchDice} />
                    <Switch label="Use Encumbrance Modifier" checked={useEncumbranceModifier} setter={setUseEncumbranceModifier} />
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Row>
                    <Button disabled={!ready} onClick={handleAbilityCheck}>Ability Check</Button>
                    <Button onClick={closeAction}>Cancel</Button>
                </Row>
            </ActionButtonsArea>
        </>
    )
}

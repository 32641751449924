import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultMore, ResultTitleArea, Title, ResultBodyArea } from "./Components"


export default function WoundsResult(props) {
    const { wounds, woundState, recoveryResult } = props

    const excludeKeys = ["character", "checkWound", "newWounds", "wounds", "medic"]
    const resultMoreProps = {
        excludeKeys,
        object: props
    }

    return (
        <>
            <ResultTitleArea>
                <Title>Wounds Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`State: ${woundState}`}
                    </ListItem>
                    {!recoveryResult ? null : <ListItem>{`Recovery Result: ${recoveryResult}`}</ListItem>}
                    <ListItem>{`Wounds: ${wounds.length === 0 ? "None" : wounds.map(el => el.type).join(", ")}`}</ListItem>
                    <ResultMore {...resultMoreProps} />
                </List>
            </ResultBodyArea>
        </>
    )
}

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultMore, ResultTitleArea, Title, ResultBodyArea } from "./Components"

export default (props) => {
    const {
        castingTotal,
        spellCast,
        fatigueCaused,
        concentrationValue,
        penetrationTotal,
        botch,
        numPawnsUsed
    } = props

    const excludeKeys = ["character", "pawns", "spell"]
    const resultMoreProps = {
        excludeKeys,
        object: props
    }

    return (
        <>
            <ResultTitleArea>
                <Title>Spell Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Spell Cast: ${spellCast ? "Yes" : "No"}`}
                    </ListItem>
                    <ListItem>
                        {`Botch: ${botch ? "Yes" : "No"} (${botch} Dice)`}
                    </ListItem>
                    <ListItem>
                        {`Fatigue Caused: ${fatigueCaused}`}
                    </ListItem>
                    <ListItem>
                        {`Concentration Succeeded: ${concentrationValue === null ? "NA" : concentrationValue >= 0 ? "Yes" : "No"}`}
                    </ListItem>
                    <ListItem>
                        {`Penetration Total: ${penetrationTotal}`}
                    </ListItem>
                    <ListItem>
                        {`Casting Total: ${castingTotal}`}
                    </ListItem>
                    <ListItem>
                        {`Number of Pawns Used: ${numPawnsUsed}`}
                    </ListItem>
                    <ResultMore {...resultMoreProps} />
                </List>
            </ResultBodyArea>
        </>
    )
}

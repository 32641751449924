import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultTitleArea, Title, ResultBodyArea } from "./Components"


export default (props) => {
    const { newEntity } = props

    return (
        <>
            <ResultTitleArea>
                <Title>Instantiation Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`New Entity Name: ${newEntity.name}`}
                    </ListItem>
                    <ListItem>
                        {`New Entity Id: ${newEntity.id}`}
                    </ListItem>
                </List>
            </ResultBodyArea>
        </>
    )
}


import { useState } from "react"
import FormControlLabel from "@mui/material/FormControlLabel"
import MUISwitch from "@mui/material/Switch"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import MUIButton from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import { capitalCase, displayName, parseNum } from "./Utils"
import { lightColor, bannerColor } from "./Styling"
import styled from "styled-components"

const characterSelectionWidth = 510
const bannerHeight = 60

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 0px 1fr 0px;
    grid-template-rows: ${bannerHeight}px 1fr 0px;
    grid-template-areas:
        "banner banner banner"
        "left-margin main right-margin"
        "bottom-margin bottom-margin bottom-margin";
    height: 100vh;
`

export const BannerArea = styled.div`
    display: grid;
    grid-area: banner;
    background: ${bannerColor};
`

export const MainArea = styled.div`
    display: grid;
    grid-area: main;
    height: 100%;
`

export const StorytellerGrid = styled.div`
    display: grid;
    grid-template-columns: 0px ${characterSelectionWidth}px fit-content(360px) minmax(240px, 1fr) 0px;
    grid-template-rows: 0px 60px 1fr 60px 0px;
    grid-template-areas:
        "top-margin top-margin top-margin top-margin top-margin"
        "left-margin character-selection action-title result-title right-margin"
        "left-margin character-tree action-body result-body right-margin"
        "left-margin buttons action-buttons result-buttons right-margin"
        "bottom-margin bottom-margin bottom-margin bottom-margin bottom-margin";
    height: calc(100vh - ${bannerHeight}px);
    gap: 16px;
`

export const CharacterSelectionArea = styled.div`
    display: grid;
    grid-area: character-selection;
`

export const CharacterTreeArea = styled(Paper)`
    display: grid;
    grid-area: character-tree;
    height: 100%;
    overflow-y: auto;
`

export const ButtonsArea = styled(Paper)`
    display: grid;
    grid-area: buttons;
    align-items: center;
    justify-content: center;
`

export const ActionTitleArea = styled(Paper)`
    display: grid;
    grid-area: action-title;
    align-items: center;
    justify-content: center;
`

export const ActionBodyArea = styled(Paper)`
    display: grid;
    grid-area: action-body;
    overflow-y: auto;
    height: 100%;
    padding: 24px;
`

export const ActionButtonsArea = styled(Paper)`
    display: grid;
    grid-area: action-buttons;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
`

export const ResultTitleArea = styled(Paper)`
    display: grid;
    grid-area: result-title;
    align-items: center;
    justify-content: center;
`

export const ResultBodyArea = styled(Paper)`
    display: grid;
    grid-area: result-body;
    overflow-y: auto;
    height: 100%;
    padding: 16px;
`

export const ResultButtonsArea = styled(Paper)`
    display: grid;
    grid-area: result-buttons;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
`

export const Title = styled.div`
    text-align: center;
    font-weight: bold;
`

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 12px
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    gap: 12px
`
export const Button = (props) => {
    return (<MUIButton variant="outlined" color="primary" {...props} />)
}

export const Switch = (props) => {
    const { label, checked, setter, disabled } = props
    return <FormControlLabel control={<MUISwitch size="small" disabled={disabled} checked={checked} onChange={event => setter(event.target.checked)} />} label={label} />
}

export const Input = (props) => {
    const { direction, label, value, error, setter } = props
    const components = (
        <>
            <div>{label}</div>
            <TextField value={value} variant="outlined" size="small" error={error} onChange={event => setter(parseNum(event.target.value))} style={{ width: "52px" }} />
        </>
    )
    if (direction === "column") {
        return (
            <Column style={{ gap: "0px" }}>
                {components}
            </Column>
        )
    } else {
        return (
            <Row>
                {components}
            </Row>
        )
    }

}

export const LabeledSelect = (props) => {
    let { direction, multiple, options, optionFunc, label, value, setter, error, style } = props
    if (!optionFunc) { optionFunc = el => el }
    multiple = multiple ? multiple : false
    const select = (<Select
        size="small"
        multiple={multiple}
        value={value}
        onChange={(event) => setter(event.target.value)}
        error={error}
        style={style}
    >
        {options.map(option => <MenuItem value={optionFunc(option)}>{optionFunc(option)}</MenuItem>)}
    </Select>)
    if (direction === "row") {
        return (
            <Row style={{ gap: "8px" }}>
                <div>{label}</div>
                {select}
            </Row>
        )
    } else if (direction === "column") {
        return (
            <Column style={{ gap: "0px" }}>
                <div>{label}</div>
                {select}
            </Column>
        )
    }
}



export const ResultMore = (props) => {
    const { excludeKeys, object } = props
    const keys = Object.keys(object).filter(key => !excludeKeys.includes(key)).sort()
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <>
            <ListItemButton onClick={handleClick}>
                <ListItemText primary="More" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div">
                    {keys.map((key) => {
                        return <ListItemText>{`${capitalCase(key)}: ${object[key]}`}</ListItemText>
                    })}
                </List>
            </Collapse>
        </>
    )
}

export const CharacterSelection = (props) => {
    let { character, characters, setCharacter, onChange } = props

    return (!characters ? null : (
        <Select
            id="character"
            value={character ? displayName(character) : ""}
            sx={{ width: characterSelectionWidth, background: lightColor }}
        >
            {[...characters].sort((a, b) => a.name > b.name).map((character, i) => {
                return (
                    <MenuItem
                        key={i}
                        value={displayName(character)}
                        onClick={() => {
                            setCharacter(character)
                            if (onChange) { onChange() }
                        }}
                    >
                        {displayName(character)}
                    </MenuItem>
                )
            })}
        </Select>
    ))
}

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultMore, ResultTitleArea, Title, ResultBodyArea } from "./Components"

export default (props) => {
    const { total, botch } = props

    const excludeKeys = []
    const resultMoreProps = {
        excludeKeys,
        object: props
    }

    return (
        <>
            <ResultTitleArea>
                <Title>Aiming Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Aiming Total: ${total}`}
                    </ListItem>
                    <ListItem>
                        {`Botch: ${botch ? "Yes" : "No"} (${botch} dice)`}
                    </ListItem>
                    <ResultMore {...resultMoreProps} />
                </List>
            </ResultBodyArea>
        </>
    )
}

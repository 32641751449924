import React, { useState } from "react"
import { Button, Row, Column, Title, Input, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"
import { simpleDieCheck, stressDieCheck } from "./Utils"


export default (props) => {
    const { openResult, closeAction } = props
    const [numBotchDice, setNumBotchDice] = useState(1)

    const handleSimpleDie = () => {
        const result = simpleDieCheck()
        openResult(result)
    }

    const handleStressDie = () => {
        const result = stressDieCheck(numBotchDice)
        openResult(result)
    }

    const ready = true

    return (
        <>
            <ActionTitleArea>
                <Title>Roll Die</Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    <Input label={"Botch Dice"} value={numBotchDice} setter={setNumBotchDice} />
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Row>
                    <Button disabled={!ready} onClick={handleSimpleDie} style={{ width: "140px" }}>Simple Die</Button>
                    <Button onClick={handleStressDie} style={{ width: "140px" }}>Stress Die</Button>
                    <Button onClick={closeAction}>Cancel</Button>
                </Row>
            </ActionButtonsArea>
        </>
    )
}

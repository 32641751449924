import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultTitleArea, Title, ResultBodyArea } from "./Components"


export default (props) => {
    const { entity, entityId, template, templateId } = props

    const message = entity ? `Deleted all ${entity.name} entries where entityId: ${entityId}` : `Deleted all ${template.name} (templateId: ${templateId}) entries`

    return (
        <>
            <ResultTitleArea>
                <Title>Delete Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {message}
                    </ListItem>
                </List>
            </ResultBodyArea>
        </>
    )
}

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultTitleArea, Title, ResultBodyArea } from "./Components"


export default (props) => {
    const { entity } = props

    return (
        <>
            <ResultTitleArea>
                <Title>Update Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Updated entity ${entity.name} (id:${entity.id})`}
                    </ListItem>
                </List>
            </ResultBodyArea>
        </>
    )
}

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultTitleArea, Title, ResultBodyArea } from "./Components"

export default (props) => {

    const { roll, botch } = props

    return (
        <>
            <ResultTitleArea>
                <Title>Roll Die Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Roll: ${roll}`}
                    </ListItem>
                    <ListItem>
                        {`Botch: ${botch ? "Yes" : "No"} (${botch} Dice)`}
                    </ListItem>
                </List>
            </ResultBodyArea>
        </>
    )
}

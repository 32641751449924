import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultMore, ResultTitleArea, Title, ResultBodyArea } from "./Components"

export default function CombatResult(props) {
    const {
        result,
        attackTotal,
        defenseTotal,
        damageTotal,
        soakTotal,
        damageTaken,
        woundTaken,
        scuffleEffect,
        attackFatigueCaused,
        defenseFatigueCaused,
    } = props

    const excludeKeys = ["character", "armor", "attackDieResult", "attackWeapons", "attackingCharacter", "defendingCharacter", "defenseDieResult", "defenseWeapons"]
    const resultMoreProps = {
        excludeKeys,
        object: props
    }

    return (
        <>
            <ResultTitleArea>
                <Title>Combat Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Result: ${result}`}
                    </ListItem>
                    <ListItem>
                        {`Wound Taken: ${woundTaken ? woundTaken : "None"}`}
                    </ListItem>
                    <ListItem>
                        {`Attack Total: ${attackTotal}`}
                    </ListItem>
                    <ListItem>
                        {`Defense Total: ${defenseTotal}`}
                    </ListItem>
                    <ListItem>
                        {`Damage Total: ${damageTotal ? damageTotal : 0}`}
                    </ListItem>
                    <ListItem>
                        {`Soak Total: ${soakTotal ? soakTotal : 0}`}
                    </ListItem>
                    <ListItem>
                        {`Damage Taken: ${damageTaken}`}
                    </ListItem>
                    <ListItem>
                        {`Attack Fatigue Caused: ${attackFatigueCaused}`}
                    </ListItem>
                    <ListItem>
                        {`Defense Fatigue Caused: ${defenseFatigueCaused}`}
                    </ListItem>
                    <ListItem>
                        {`Scuffle Effect: ${scuffleEffect}`}
                    </ListItem>
                    <ResultMore {...resultMoreProps} />
                </List>
            </ResultBodyArea>
        </>
    )
}

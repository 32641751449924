import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ResultMore, ResultTitleArea, Title, ResultBodyArea } from "./Components"

export default (props) => {
    const { fatigue, fatigueDescription, fatigueModifier, fatigueRecoveryTime } = props

    const excludeKeys = ["wounds"]
    const resultMoreProps = {
        excludeKeys,
        object: props
    }

    return (
        <>
            <ResultTitleArea>
                <Title>Fatigue Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Condition: ${fatigueDescription}`}
                    </ListItem>
                    <ListItem>
                        {`Fatigue Levels: ${fatigue.length === 0 ? "None" : fatigue.join(", ")}`}
                    </ListItem>
                    <ListItem>
                        {`Modifier: ${fatigueModifier}`}
                    </ListItem>
                    <ListItem>
                        {`Recovery Time: ${fatigueRecoveryTime}`}
                    </ListItem>
                    <ResultMore {...resultMoreProps} />
                </List>
            </ResultBodyArea>
        </>
    )
}

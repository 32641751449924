import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ResultMore, ResultTitleArea, Title, ResultBodyArea } from "./Components"

export default (props) => {
    const { total, botch, checkPassed } = props

    const excludeKeys = ["character", "wounds"]
    const resultMoreProps = {
        excludeKeys,
        object: props
    }

    return (
        <>
            <ResultTitleArea>
                <Title>Ability Result</Title>
            </ResultTitleArea>
            <ResultBodyArea>
                <List>
                    <ListItem>
                        {`Check Passed: ${checkPassed ? "Yes" : "No"}`}
                    </ListItem>
                    <ListItem>
                        {`Total: ${total}`}
                    </ListItem>
                    <ListItem>
                        {`Botch: ${botch ? "Yes" : "No"} (${botch} Dice)`}
                    </ListItem>
                    <ResultMore {...resultMoreProps} />
                </List>
            </ResultBodyArea>
        </>
    )
}

import React, { useState } from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { addWound, recoveryCheck, getScore } from "./Utils"
import { scoreTypesEnum, woundsModifiers, modifyWoundsActionsEnum, medicAbilities } from "./Enums"
import { LabeledSelect, Button, Input, Row, Column, Title, ActionTitleArea, ActionBodyArea, ActionButtonsArea } from "./Components"
import { cloneDeep } from "lodash"

export default (props) => {
    const { character, characters, handleUpdateCharacter, openResult, closeAction } = props

    const [newWoundType, setNewWoundType] = useState("")
    const [woundIndex, setWoundIndex] = useState(0)
    const [medic, setMedic] = useState(null)
    const [medicAbility, setMedicAbility] = useState(null)
    const [magicalAid, setMagicalAid] = useState(0)
    const [modifier, setModifier] = useState(0)

    const woundTypes = woundsModifiers.map(el => el.type)

    const handleModifyWounds = ({ action }) => {
        let result = null
        if (action === modifyWoundsActionsEnum.ADD) {
            result = addWound({ wounds: character.wounds, newWoundType })
        } else if (action === modifyWoundsActionsEnum.RECOVERY) {
            result = recoveryCheck({ character, woundIndex, medic, medicAbility, magicalAid, modifier })
            setWoundIndex(0)
        }
        if (result) {
            const newWounds = result.values.wounds
            const newCharacter = { ...cloneDeep(character), wounds: newWounds }
            handleUpdateCharacter({ newCharacter })
            openResult(result)
        }
    }

    const woundRecoveryComponent = character.wounds.length > 0 ? (
        <>
            <div style={{ width: "180px", marginRight: "8px" }}>Wound Recovery</div>
            <Select
                id="wound-recovery"
                size="small"
                value={woundIndex}
                renderValue={() => { return `${woundIndex}: ${character.wounds[woundIndex].type}` }}
                onChange={(event) => { setWoundIndex(event.target.value) }}
            >
                {character.wounds.map((el, i) => <MenuItem value={i}>{`${i}: ${el.type}`}</MenuItem>)}
            </Select>
            <LabeledSelect direction="column" options={characters} optionFunc={el => el.name} label="Medic" value={medic ? medic.name : ""} setter={value => { return setMedic(characters.filter(character => character.name === value)[0]) }} />
            <LabeledSelect direction="column" options={medicAbilities} label="Medic Ability" value={medicAbility} setter={setMedicAbility} />
            <div>{`Medic Ability Score: ${(!medic || !medicAbility) ? 0 : getScore({ character: medic, scoreType: scoreTypesEnum.ABILITIES, scoreName: medicAbility })}`}</div>
            <Input direction="row" label="Magical Aid" value={magicalAid} setter={setMagicalAid} />
            <Input direction="row" label="Modifier" value={modifier} setter={setModifier} />

        </>
    ) : null

    return (
        <>
            <ActionTitleArea>
                <Title>Wounds</Title>
            </ActionTitleArea>
            <ActionBodyArea>
                <Column>
                    <LabeledSelect direction="column" options={woundTypes} label="New Wound Type" value={newWoundType} setter={setNewWoundType} style={{ marginBottom: "40px" }} />
                    {woundRecoveryComponent}
                </Column>
            </ActionBodyArea>
            <ActionButtonsArea>
                <Row>
                    <Button disabled={!newWoundType} onClick={() => { handleModifyWounds({ action: modifyWoundsActionsEnum.ADD }) }} style={{ width: "140px" }}>Add Wound</Button>
                    <Button disabled={character.wounds.length === 0} onClick={() => { handleModifyWounds({ action: modifyWoundsActionsEnum.RECOVERY }) }} style={{ width: "180px" }}>Recovery Check</Button>
                    <Button onClick={closeAction}>Cancel</Button>
                </Row>
            </ActionButtonsArea>
        </>
    )
}

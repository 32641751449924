import React, { useState } from "react"
import Paper from "@mui/material/Paper"
import { useParams } from "react-router-dom"
import { formatModifier } from "./Utils"
import { CharacterSelection, Row, Column } from "./Components"
import { techniques, forms, characteristics as characteristicsOptions } from "./Enums"

export default (props) => {

    const { campaign, characters } = props
    const { year } = campaign

    const params = useParams()
    const { id: reqId, player: reqPlayer } = params

    let availableCharacters = []
    if (reqId !== undefined && reqId !== null) {
        availableCharacters = characters.filter(el => el.id === parseInt(reqId))
    } else if (reqPlayer) {
        availableCharacters = characters.filter(el => el.player && el.player.toLowerCase() === reqPlayer)
    } else {
        availableCharacters = characters
    }

    if (availableCharacters.length === 0) {

        return null

    } else {

        const [character, setCharacter] = useState(availableCharacters[0])

        const {
            name,
            player,
            birthYear,
            size,
            class: characterClass,
            might,
            house,
            description,
            note,
            decreptitude,
            warping,
            personality,
            reputations,
            confidence,
            characteristics,
            arts,
            virtues,
            flaws,
            qualities,
            inferiorities,
            abilities,
            powers,
            spells,
            fatigue,
            wounds,
            weapons,
            armor,
            equipment,
        } = character

        // const abilityKeys = abilities ? Object.keys(abilities).sort() : []
        const spellKeys = spells ? Object.keys(spells).sort() : []
        const weaponsSorted = weapons
            ? [...weapons].sort((a, b) => a.name - b.name)
            : []
        const equipmentSorted = equipment
            ? [...equipment].sort((a, b) => a.name - b.name)
            : []
        const characterSelectionProps = {
            character, characters: availableCharacters, setCharacter
        }

        const LabeledValue = ({ label, value, labelStyle }) => {
            return (
                <Row style={{ gap: "6px", alignItems: "flex-start" }}>
                    <div style={{ fontWeight: "bold", ...labelStyle }}>{`${label}:`}</div>
                    <div>{value}</div>
                </Row>
            )
        }

        const ScoreList = ({ label, keys, object }) => {
            if (!keys) {
                keys = [...Object.keys(object)].sort()
            }
            return (
                <div style={{ gap: "0px" }}>
                    <LabeledValue label={label} />
                    <ul style={{ margin: "0px", paddingLeft: "16px", listStyleType: "none", }} >
                        {keys.map((key) => {
                            return (<li>{`${key}: ${formatModifier(object[key].score)}`}</li>)
                        })}
                    </ul>
                </div>
            )
        }

        const StyledUl = ({ children, style }) => {
            children = React.Children.toArray(children)
            return <ul
                style={{
                    margin: "0px",
                    paddingLeft: "16px",
                    listStyleType: "none",
                    ...style
                }}
            >{children.map(child => { return <li>{child}</li> })}</ul>
        }

        const StyledList = ({ label, object }) => {
            const keys = [...Object.keys(object)].sort()
            return (
                <div style={{ gap: "0px" }}>
                    <LabeledValue label={label} />
                    <ul style={{ margin: "0px", paddingLeft: "16px" }} >
                        {keys.map((key) => {
                            return (<li>{key}</li>)
                        })}
                    </ul>
                </div>
            )
        }

        return !character ? null : (
            <Paper sx={{ margin: "8px", padding: "24px" }}>
                <Column>
                    <Row style={{ justifyContent: "center" }}><CharacterSelection {...characterSelectionProps} /></Row>
                    <Row style={{ justifyContent: "space-around" }}>
                        <Column style={{ gap: "24px" }}>
                            <Row style={{ gap: "24px", alignItems: "flex-start" }}>
                                <Column style={{ gap: "8px", maxWidth: "200px" }}>
                                    <LabeledValue label="Name" value={name} />
                                    {!player ? null : <LabeledValue label="Player" value={player} />}
                                    {!characterClass ? null : <LabeledValue label="Class" value={characterClass} />}
                                    {!house ? null : <LabeledValue label="House" value={house} />}
                                    {!birthYear ? null : <LabeledValue label="Age" value={year - birthYear} />}
                                    {!might ? null : <LabeledValue label="Might" value={`${might.score} (${might.realm}, ${might.form})`} />}
                                    <LabeledValue label="Size" value={size} />
                                    <ScoreList label="Characteristics" keys={characteristicsOptions} object={characteristics} />
                                </Column>
                                <Column style={{ gap: "8px" }}>
                                    {!confidence ? null : (
                                        <div style={{ gap: "0px" }}>
                                            <LabeledValue label="Confidence" />
                                            <StyledUl>
                                                <LabeledValue label="Score" value={confidence.score} labelStyle={{ fontWeight: "normal" }} />
                                                <LabeledValue label="Points" value={confidence.points} labelStyle={{ fontWeight: "normal" }} />
                                            </StyledUl>
                                        </div>
                                    )}
                                    {!decreptitude ? null : (
                                        <div style={{ gap: "0px" }}>
                                            <LabeledValue label="Decreptitude" />
                                            <StyledUl>
                                                <LabeledValue label="Score" value={decreptitude.score} labelStyle={{ fontWeight: "normal" }} />
                                                <LabeledValue label="Effects" value={decreptitude.effects.join(", ")} labelStyle={{ fontWeight: "normal" }} />
                                            </StyledUl>
                                        </div>
                                    )}
                                    {!warping ? null : (
                                        <div style={{ gap: "0px" }}>
                                            <LabeledValue label="Warping" />
                                            <StyledUl>
                                                <LabeledValue label="Score" value={warping.score} labelStyle={{ fontWeight: "normal" }} />
                                                <LabeledValue label="Effects" value={warping.effects.join(", ")} labelStyle={{ fontWeight: "normal" }} />
                                            </StyledUl>
                                        </div>
                                    )}
                                    <div style={{ gap: "0px" }}>
                                        <LabeledValue label="Fatigue" />
                                        <StyledUl>
                                            {fatigue.length === 0 ? "None" : fatigue.map((el) => { return <li>{el}</li> })}
                                        </StyledUl>
                                    </div>
                                    <div style={{ gap: "0px" }}>
                                        <LabeledValue label="Wounds" />
                                        <StyledUl>
                                            {wounds.length === 0 ? "None" : wounds.map((el) => { return <li>{`${el.type}`}</li> })}
                                        </StyledUl>
                                    </div>
                                </Column>
                                <Column style={{ maxWidth: "400px" }}>
                                    <div style={{ gap: "0px" }}>
                                        <LabeledValue label="Personality" />
                                        <StyledUl>
                                            {!personality ? null : [...Object.keys(personality)].sort().map(key => { return <li>{`${key}: ${formatModifier(personality[key])}`}</li> })}
                                        </StyledUl>
                                    </div>
                                    <div style={{ gap: "0px" }}>
                                        <LabeledValue label="Reputations" />
                                        <StyledUl>
                                            {!reputations ? null : [...Object.keys(reputations)].sort().map(key => { return <li>{`${key}: ${reputations[key].score} (${reputations[key].group})`}</li> })}
                                        </StyledUl>
                                    </div>
                                    {!virtues ? null : <StyledList label="Virtues" object={virtues} />}
                                    {!flaws ? null : <StyledList label="Flaws" object={flaws} />}
                                </Column>
                                <Column>
                                    {!qualities ? null : <StyledList label="Qualities" object={qualities} />}
                                    {!inferiorities ? null : <StyledList label="Inferiorities" object={inferiorities} />}
                                </Column>
                                {!abilities ? null : (
                                    <div style={{ gap: "0px" }}>
                                        <LabeledValue label="Abilities" />
                                        <StyledUl style={{ listStyleType: "disc" }} >
                                            {[...Object.keys(abilities)].sort().map(key => {
                                                return <li>{`${key}: ${abilities[key].score} ${abilities[key].specialty
                                                    ? `(${abilities[key].specialty})`
                                                    : ""
                                                    }`}</li>
                                            })}
                                        </StyledUl>
                                    </div>
                                )
                                }
                                {!powers ? null : (
                                    <div style={{ gap: "0px", maxWidth: "300px" }}>
                                        <LabeledValue label="Powers" />
                                        <StyledUl style={{ listStyleType: "disc" }}>
                                            {[...Object.keys(powers).sort().map(key => {
                                                const { points, initiative, form, note } = powers[key]
                                                return <li>{`${key} (${points} points, ${formatModifier(initiative)} init, ${form})${!note ? "" : " " + note}`}

                                                </li>
                                            })]}
                                        </StyledUl>
                                    </div>
                                )
                                }
                                {!arts ? null : <div>
                                    {Object.entries(arts).length === 0 ? null : (
                                        <div style={{ minWidth: 60 }}>
                                            <div style={{ fontWeight: "bold" }}>Techniques:</div>
                                            <ul
                                                style={{
                                                    margin: "0px",
                                                    paddingLeft: "16px",
                                                    listStyleType: "none",
                                                }}
                                            >
                                                {techniques.map((key) => {
                                                    return (
                                                        <li>{`${key}: ${arts[key].score}`}</li>
                                                    )
                                                })}
                                            </ul>
                                            <div style={{ fontWeight: "bold" }}>Forms:</div>
                                            <ul
                                                style={{
                                                    margin: "0px",
                                                    paddingLeft: "16px",
                                                    listStyleType: "none",
                                                }}
                                            >
                                                {forms.map((key) => {
                                                    return (
                                                        <li>{`${key}: ${arts[key].score}`}</li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </div>}
                                {!spells ? null : (
                                    <div>
                                        {Object.entries(spells).length === 0 ? null : (
                                            <>
                                                <div style={{ fontWeight: "bold" }}>
                                                    Spells:
                                                </div>
                                                <ul
                                                    style={{
                                                        margin: "0px",
                                                        paddingLeft: "16px",
                                                    }}
                                                >
                                                    {spellKeys.map((key) => {
                                                        return (
                                                            <li>{`${key} (${spells[key].technique}${spells[key].form} ${spells[key].level})`}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                )}
                                <div style={{ maxWidth: "200px" }}>
                                    {!weapons ? null : (
                                        <>
                                            <div style={{ fontWeight: "bold" }}>Weapons:</div>
                                            <ul style={{ margin: "0px", paddingLeft: "16px" }}>
                                                {weaponsSorted.map((weapon) => {
                                                    return (
                                                        <li>{`${weapon.name
                                                            } (I:${formatModifier(
                                                                weapon.initiative
                                                            )}, A:${formatModifier(
                                                                weapon.attack
                                                            )}, Dfn:${formatModifier(
                                                                weapon.defense
                                                            )}, Dam:${formatModifier(
                                                                weapon.damage
                                                            )}, R:${weapon.range}, L:${weapon.load
                                                            })`}</li>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    )}
                                    {!armor ? null : (
                                        <>
                                            <div style={{ fontWeight: "bold" }}>Armor:</div>
                                            <ul
                                                style={{
                                                    margin: "0px",
                                                    paddingLeft: "16px",
                                                }}
                                            >
                                                <li>{`${armor.name} (Prot:${armor.protection}, Load:${armor.load})`}</li>
                                            </ul>
                                        </>
                                    )}
                                    {!equipment ? null : (
                                        <>
                                            <div style={{ fontWeight: "bold" }}>
                                                Equipment (Load):
                                            </div>
                                            <ul
                                                style={{
                                                    margin: "0px",
                                                    paddingLeft: "16px",
                                                }}
                                            >
                                                {equipmentSorted.length === 0 ? <li>None</li> : equipmentSorted.map((el) => {
                                                    return <li>{`${el.name} (${el.load})`}</li>
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </Row>
                            <Row style={{ gap: "24px", alignItems: "flex-start" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "600px" }}>
                                    <div style={{ fontWeight: "bold" }}>
                                        Description
                                    </div>
                                    <div>{description}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ minWidth: "400px", fontWeight: "bold" }}>
                                        Notes
                                    </div>
                                    <div>{note}</div>
                                </div>
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Paper>
        )
    }
}